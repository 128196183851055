import React, { useEffect, useRef } from 'react';
import { Box, Paper, Typography, Button, Grid } from '@mui/material';
import { Chart } from 'chart.js/auto';

const WorkshopCard = ({ percentage, name }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        // Destroy existing chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Create new chart instance
        chartInstanceRef.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ['Completed', 'Remaining'],
                datasets: [
                    {
                        data: [percentage, 100 - percentage],
                        backgroundColor: ['#FF6384', '#E0E0E0'],
                        hoverBackgroundColor: ['#FF6384', '#E0E0E0'],
                        borderWidth: 0,
                    },
                ],
            },
            options: {
                cutout: '80%',
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.raw !== null) {
                                    label += context.raw + '%';
                                }
                                return label;
                            },
                        },
                    },
                },
            },
        });

        // Cleanup function to destroy chart instance on unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [percentage]);

    return (
        <Paper
            sx={{
                height: '270px',
                padding: 2,
                borderRadius: '16px',
                width: { xs: '90%', sm: 250 }, // Full width on small screens
                textAlign: 'center',
                backgroundColor: '#fff',
                borderColor: '#bec4ec',
                borderWidth: '1px',
                borderStyle: 'solid',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    width: 120,
                    height: 120,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <canvas ref={chartRef} />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 55,
                        left: 6,
                        bottom: 0,
                        right: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.2rem',
                    }}
                >
                    <Typography variant="h6">{`${percentage}%`}</Typography>
                </Box>
            </Box>
            <Typography variant="h6" sx={{ mt: 2 }}>{`Workshop ${name}`}</Typography>
            <Button
                variant="contained"
                color="warning"
                sx={{
                    mt: 2,
                    backgroundColor: '#e79a2b',
                    color: '#fff',
                    borderRadius: '8px',
                    borderColor: '#8c5000',
                    padding: '8px 16px',
                }}
            >
                Complete Now
            </Button>
        </Paper>
    );
};

const OngoingWorkshops = () => {
    return (
        <Box
            sx={{
                padding: 2,
                borderRadius: '24px',
                backgroundColor: '#f9fafe',
                borderColor: '#d3d3d3',
                borderWidth: '1px',
                borderStyle: 'solid',
                alignItems: 'center',
                flex: 1,
            }}
        >
            <Typography
                variant="h6"
                align="center"
                sx={{ fontSize: 24, fontWeight: '700', color: '#000', textAlign: 'center' }}
            >
                Ongoing Workshops
            </Typography>
            <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
                sx={{ mt: 0, textAlign: 'center', color: '#7a7a7a', fontWeight: '500', fontSize: 14 }}
            >
                ongoing workshops - 2
            </Typography>
            <Grid
                container
                spacing={3}
                sx={{ mt: 0 }}
            >
                <Grid item xs={12} sm={6}>
                    <WorkshopCard percentage={84} name="<name>" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <WorkshopCard percentage={65} name="<name>" />
                </Grid>
            </Grid>
        </Box>
    );
};

export default OngoingWorkshops;
