import React, { useState, useEffect } from 'react';
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
  Box,
  Tooltip,
} from '@mui/material';
import {
  AccountCircle,
  CalendarToday as CalendarTodayIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateTL } from '../redux/slices/userSlice';
import TinkerLabListAPIs from 'utilities/api/TinkerLabListAPIs';
import ImageBaseURL from 'utilities/api/axios';
import Sidebar from './sidebar'; // Import the Sidebar component

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [tlList, setTlList] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false); // State to manage Sidebar open state
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // Adjust size based on screen size
  const size = isSmallScreen ? '28px' : '36px';

  const circularStyle = {
    borderRadius: '50%',
    width: size,
    height: size,
    minWidth: size,
    padding: 0,
    margin: '0 4px',
  };

  const handleTLChange = (event) => {
    dispatch(updateTL({ TL: event.target.value }));
  };

  useEffect(() => {
    const fetchTLList = async () => {
      try {
        const data = await TinkerLabListAPIs.TinkerLabsListGet();
        setTlList(data);
      } catch (error) {
        console.error('Error fetching TL data:', error);
      }
    };

    if (user.is_org_admin) {
      fetchTLList();
    }
  }, [user]);

  const userAvatarUrl = user.profile_pic
    ? `${ImageBaseURL.defaults.baseURL}${user.profile_pic}`
    : '';

  return (
    <>
      <AppBar
        position="static"
        elevation={1}
        sx={{
          width: '100%',
          background: 'var(--s-primary-50, #F2F3FC)',
          padding: isSmallScreen ? '4px 8px' : '0 16px',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 'auto !important',
          }}
        >
          {/* Left Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isSmallScreen && (
              <Button
                onClick={() => setOpenSidebar(true)}
                variant="contained"
                style={circularStyle}
                color="primary"
              >
                <MenuIcon fontSize="small" />
              </Button>
            )}
            {user.is_org_admin ? (
              <Select
                value={user.TL_name}
                onChange={handleTLChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select TL' }}
                sx={{
                  fontSize: isSmallScreen ? '0.875rem' : '1rem',
                  width: isSmallScreen ? '120px' : 'auto',
                  marginLeft: isSmallScreen ? '8px' : '16px',
                }}
              >
                {tlList.map((tl) => (
                  <MenuItem key={tl.id} value={tl.name}>
                    {tl.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Tooltip title={user.TL_name} arrow>
                <Typography
                  variant="h6"
                  className="poweredBy"
                  sx={{
                    fontSize: isSmallScreen ? '1rem' : '1.25rem',
                    marginLeft: isSmallScreen ? '8px' : '16px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: isSmallScreen ? '140px' : 'none',
                    cursor: 'default',
                  }}
                >
                  {user.TL_name}
                </Typography>
              </Tooltip>
            )}
          </Box>


          {/* Right Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {user.TL_name !== 'Org-Admin' && (
              <Link to="/calendar">
                <Button
                  variant="contained"
                  style={circularStyle}
                  color="primary"
                >
                  <CalendarTodayIcon fontSize="small" />
                </Button>
              </Link>
            )}
            <Link to="/profile">
              <Button
                edge="end"
                variant="contained"
                style={circularStyle}
                color="primary"
              >
                {userAvatarUrl ? (
                  <img
                    src={userAvatarUrl}
                    alt="Profile"
                    style={{
                      borderRadius: '50%',
                      width: size,
                      height: size,
                    }}
                  />
                ) : (
                  <AccountCircle fontSize="small" />
                )}
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Render Sidebar on small screens */}
      {isSmallScreen && (
        <Sidebar open={openSidebar} onClose={() => setOpenSidebar(false)} />
      )}
    </>
  );
};

export default Header;
