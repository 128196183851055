import React, { useState, useEffect, useCallback } from 'react';
import {
    TableContainer,
    Paper,
    Box,
    TextField,
    InputAdornment,
    Typography,
    Button,
    CircularProgress,
    Table,
    TableBody,
    useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination';
import WorkbenchBookingHeader from './WorkbenchBookingHeader';
import WorkbenchBookingListItem from './WorkbenchBookingListItem';
import WorkbenchBookingCards from './ResponsiveCardComponents/WorkbenchBookingCards';
import BookWorkBench from 'components/ExploreTL/workbench/BookWorkBench';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WorkbenchBookingAPIs from 'utilities/api/StudentWorkbenchBookingAPIs';

const WorkbenchBookingsTab = () => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Detect small screens
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [bookings, setBookings] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openBookDialog, setOpenBookDialog] = useState(false);
    const [productsPerPage, setProductsPerPage] = useState(4);

    const fetchBookings = async () => {
        try {
            setLoading(true);
            const data = await WorkbenchBookingAPIs.getList();
            setBookings(data);
            setFilteredData(data);
        } catch (error) {
            setError('Failed to fetch bookings. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const filterData = useCallback(() => {
        setFilteredData(
            bookings.filter((item) =>
                (item.project?.title?.toLowerCase() || 'no project').includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, bookings]);

    useEffect(() => {
        fetchBookings();
    }, []);

    useEffect(() => {
        filterData();
        setCurrentPage(1);
    }, [searchQuery, filterData]);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / productsPerPage);

    const currentProducts = filteredData.slice(
        (currentPage - 1) * productsPerPage,
        currentPage * productsPerPage
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value > totalPages ? totalPages : value);
    };

    const handleDelete = async (id) => {
        try {
            await WorkbenchBookingAPIs.deleteBooking(id);
            setBookings((prev) => prev.filter((booking) => booking.id !== id));
            setFilteredData((prev) => prev.filter((booking) => booking.id !== id));
        } catch (error) {
            console.error('Failed to delete the booking:', error);
        }
    };

    const handleOpenDialog = () => setOpenBookDialog(true);

    const handleCloseDialog = () => {
        setOpenBookDialog(false);
        fetchBookings();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="h6" color="error">
                            {error}
                        </Typography>
                    </Box>
                ) : filteredData.length > 0 ? (
                    <>
                        <Box
                            className="headerContainer"
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                justifyContent: { xs: 'center', sm: 'space-between' },
                                alignItems: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            <TextField
                                label="Search Projects"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ m: 1, minWidth: 250 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenDialog}
                                sx={{
                                    backgroundColor: "#E79A2B",
                                    color: "white",
                                    borderRadius: "8px",
                                    padding: "8px 16px",
                                    textTransform: "none",
                                    border: "2px solid #212121",
                                }}
                            >
                                Book Work Bench
                            </Button>
                        </Box>

                        {isSmallScreen ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                                {currentProducts.map((item) => (
                                    <WorkbenchBookingCards key={item.id} item={item} onUpdate={fetchBookings}/>
                                ))}
                            </Box>
                        ) : (
                            <TableContainer
                                component={Paper}
                                elevation={0}
                                sx={{
                                    overflowY: 'auto',
                                    maxHeight: '590px',
                                    border: '1px solid black',
                                    borderRadius: '20px',
                                }}
                            >
                                <Table stickyHeader>
                                    <WorkbenchBookingHeader />
                                    <TableBody>
                                        {currentProducts.map((item) => (
                                            <WorkbenchBookingListItem
                                                key={item.id}
                                                product={item}
                                                onDelete={handleDelete}
                                                onUpdate={fetchBookings}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                                {totalPages > 0 && (
                                    <CustomPagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        productsPerPage={productsPerPage}
                                        setProductsPerPage={setProductsPerPage}
                                    />
                                )}
                            </TableContainer>
                        )}
                    </>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} p={4}>
                        <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                        <Typography variant="h6" color="textSecondary">
                            No data available
                        </Typography>
                    </Box>
                )}

                {openBookDialog && <BookWorkBench open={openBookDialog} onClose={handleCloseDialog} />}
            </Box>
        </LocalizationProvider>
    );
};

export default WorkbenchBookingsTab;
