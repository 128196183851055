import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    IconButton,
    Select,
    MenuItem,
    DialogContentText,
    CircularProgress,
    TextareaAutosize
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkIcon from '@mui/icons-material/Work';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete Icon
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import WorkbenchAPIs from 'utilities/api/StudentWorkbenchAPIs';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import WorkbenchBookingAPIs from 'utilities/api/StudentWorkbenchBookingAPIs';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';

const EditWorkbenchBooking = ({ open, onClose, bookingData, onUpdate }) => {
    const { user } = useSelector((state) => state.user);

    const [workbenchList, setWorkbenchList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedWorkbench, setSelectedWorkbench] = useState(null);
    const [projectDescription, setProjectDescription] = useState('');
    const [submissionError, setSubmissionError] = useState('');
    const [loading, setLoading] = useState(false);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // For delete confirmation
    const [deleteLoading, setDeleteLoading] = useState(false); // For delete loading state
    const [deleteError, setDeleteError] = useState(''); // For delete errors

    useEffect(() => {
        const fetchWorkbenches = async () => {
            try {
                const response = await WorkbenchAPIs.getList();
                setWorkbenchList(response);
            } catch (error) {
                console.error('Error fetching workbench list:', error);
            }
        };

        const fetchProjects = async () => {
            try {
                const response = await ProjectAPIs.ProjectsGet();
                setProjectList(response);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchWorkbenches();
        fetchProjects();
    }, []);

    const handleUserSearch = async (query) => {
        try {
            const response = await StudentGetUser.UserDetailsGet(query);
            setUserOptions(response);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        if (searchQuery.length > 0) {
            const delayDebounceFn = setTimeout(() => {
                handleUserSearch(searchQuery);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (bookingData && bookingData.group && bookingData.group.length > 0) {
            const initializeGroup = () => {
                try {
                    const groupLength = bookingData.group.length;
                    const groupNamesLength = bookingData.group_names.length;

                    if (groupLength !== groupNamesLength) {
                        console.warn('Mismatch between group IDs and group names lengths.');
                    }

                    const initialGroupMembers = bookingData.group.map((id, index) => ({
                        id,
                        name: bookingData.group_names[index] || 'Unknown',
                    }));

                    setGroupOptions(initialGroupMembers);
                } catch (error) {
                    console.error('Error initializing group members:', error);
                    const fallbackGroupMembers = bookingData.group_names.map((name, index) => ({
                        id: bookingData.group[index] || `fallback-${index}`,
                        name,
                    }));
                    setGroupOptions(fallbackGroupMembers);
                }
            };

            initializeGroup();
        } else {
            setGroupOptions([{ id: user.id, name: user.username }]);
        }
    }, [bookingData, user]);

    const validationSchema = Yup.object({
        workBench: Yup.string().required('Please select a workbench'),
        date: Yup.date()
            .required('Please select a date')
            .nullable()
            .test('is-future-date', 'Date cannot be in the past', (value) => {
                if (!value) return true;
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return value >= today;
            }),
        startTime: Yup.string()
            .required('Please select a start time')
            .test('is-future-start-time', 'Start time must be in the future', function (value) {
                const { date } = this.parent;
                if (!date || !value) return true;
                const selectedDateTime = new Date(date);
                const [startHours, startMinutes] = value.split(':');
                selectedDateTime.setHours(startHours, startMinutes);
                return selectedDateTime > new Date();
            }),
        endTime: Yup.string()
            .required('Please select an end time')
            .test('is-future-end-time', 'End time must be in the future', function (value) {
                const { date } = this.parent;
                if (!date || !value) return true;
                const selectedDateTime = new Date(date);
                const [endHours, endMinutes] = value.split(':');
                selectedDateTime.setHours(endHours, endMinutes);
                return selectedDateTime > new Date();
            })
            .test(
                'is-after-start',
                'End time must be after start time and have at least 30 minutes gap',
                function (value) {
                    const { startTime, date } = this.parent;
                    if (!startTime || !value || !date) return true;
                    const startDateTime = new Date(date);
                    const [startHours, startMinutes] = startTime.split(':');
                    startDateTime.setHours(startHours, startMinutes);

                    const endDateTime = new Date(date);
                    const [endHours, endMinutes] = value.split(':');
                    endDateTime.setHours(endHours, endMinutes);

                    const timeDifference = (endDateTime - startDateTime) / (1000 * 60);
                    return endDateTime > startDateTime && timeDifference >= 30;
                }
            ),
        project: Yup.string().nullable(),
        group: Yup.array()
            .min(1, 'Select at least one group member')
            .test('max-group-size', 'Group exceeds available seats', function (value) {
                const { workBench } = this.parent;
                const selectedBench = workbenchList.find((bench) => bench.id === parseInt(workBench));
                return selectedBench ? value.length <= selectedBench.seats : true;
            }),
        independentDescription: Yup.string().required('Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            workBench: bookingData.workbench ? bookingData.workbench.toString() : '',
            date: bookingData.booking_date ? new Date(bookingData.booking_date) : null,
            startTime: bookingData.start_time ? bookingData.start_time.slice(0, 5) : '',
            endTime: bookingData.end_time ? bookingData.end_time.slice(0, 5) : '',
            project: bookingData.project ? bookingData.project.toString() : '',
            group: bookingData.group || [],
            independentDescription: bookingData.description || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmissionError('');
            setLoading(true);
            try {
                const bookingPayload = {
                    workbench: parseInt(values.workBench),
                    booking_date: values.date ? values.date.toISOString().split('T')[0] : null,
                    start_time: values.startTime + ':00',
                    end_time: values.endTime + ':00',
                    group: values.group,
                    description: values.independentDescription,
                    project: values.project ? parseInt(values.project) : null,
                };

                await WorkbenchBookingAPIs.updateBooking(bookingData.id, bookingPayload);
                console.log('Booking updated successfully');
                onUpdate();
                onClose();
            } catch (error) {
                console.error('Error updating booking:', error);
                setSubmissionError('Failed to update booking. Please try again.');
            } finally {
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    const handleWorkBenchChange = (event) => {
        const selectedBenchId = event.target.value;
        formik.setFieldValue('workBench', selectedBenchId);
        const selectedBench = workbenchList.find((bench) => bench.id === parseInt(selectedBenchId));
        setSelectedWorkbench(selectedBench);
    };

    useEffect(() => {
        if (formik.values.project) {
            const selectedProject = projectList.find((proj) => proj.id === parseInt(formik.values.project));
            if (selectedProject) {
                setProjectDescription(selectedProject.description || 'No description available.');
            }
        } else {
            setProjectDescription('N/A');
        }
    }, [formik.values.project, projectList]);

    const handleClose = () => {
        formik.resetForm();
        setSubmissionError('');
        setProjectDescription('');
        onClose();
    };

    // Function to open the delete confirmation dialog
    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };

    // Function to close the delete confirmation dialog
    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setDeleteError('');
    };

    // Function to handle the deletion of the booking
    const handleDeleteBooking = async () => {
        setDeleteLoading(true);
        setDeleteError('');
        try {
            await WorkbenchBookingAPIs.deleteBooking(bookingData.id);
            console.log('Booking deleted successfully');
            onUpdate();
            onClose();
        } catch (error) {
            console.error('Error deleting booking:', error);
            setDeleteError('Failed to delete booking. Please try again.');
        } finally {
            setDeleteLoading(false);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                sx={{
                    height: '100%',
                    '& .MuiDialog-paper': {
                        borderRadius: '20px',
                    },
                }}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <Typography variant="h6" fontWeight="bold" color="#26307A">
                            Edit Workbench Booking
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <IconButton
                                sx={{
                                    backgroundColor: '#F2F3FC',
                                    borderRadius: '50%',
                                    p: 0.5,
                                    border: '1px solid #D3D3D3',
                                    mt: { xs: 1, sm: 0 },
                                }}
                            >
                                <InfoOutlinedIcon sx={{ color: '#5C5C5C' }} />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        {/* User Info */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 1, width: { xs: '100%', sm: '320px' } }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <AccountCircleIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <Typography variant="h6">{bookingData.username || user.username}</Typography>
                        </Box>

                        {/* Workbench Selection */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 1, width: { xs: '100%', sm: '320px' } }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <WorkIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <FormControl fullWidth margin="normal" sx={{ ml: { sm: 2, xs: 0 } }}>
                                <InputLabel sx={{ background: 'white' }}>Select Workbench</InputLabel>
                                <Select
                                    value={formik.values.workBench}
                                    onChange={handleWorkBenchChange}
                                    error={Boolean(formik.touched.workBench && formik.errors.workBench)}
                                    sx={{ borderRadius: '8px' }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                overflowX: 'auto', // Enable horizontal scrolling
                                            },
                                        },
                                    }}
                                >
                                    {workbenchList.map((bench) => (
                                        <MenuItem key={bench.id} value={bench.id.toString()}>
                                            Workbench {bench.id} - {bench.available_machines} Machines ({bench.slot_from} - {bench.slot_to})
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.workBench && formik.errors.workBench && (
                                    <Typography color="error">{formik.errors.workBench}</Typography>
                                )}
                            </FormControl>
                        </Box>


                        {/* Date Selection */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 2.5, width: { xs: '100%', sm: '320px' } }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <CalendarTodayIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <DatePicker
                                label="Select Date"
                                value={formik.values.date}
                                onChange={(newValue) => formik.setFieldValue('date', newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{ ml: { sm: 2, xs: 0 }, borderRadius: '8px' }}
                                        error={Boolean(formik.touched.date && formik.errors.date)}
                                    />
                                )}
                            />
                            {formik.touched.date && formik.errors.date && (
                                <Typography color="error">{formik.errors.date}</Typography>
                            )}
                        </Box>

                        {/* Time Selection */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 0, width: { xs: '100%', sm: '320px' } }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <AccessTimeIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    width: '100%',
                                    ml: { sm: 1, xs: 0 },
                                }}
                            >
                                <TextField
                                    type="time"
                                    label="From Time"
                                    value={formik.values.startTime}
                                    onChange={(e) => formik.setFieldValue('startTime', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        width: { xs: '100%', sm: '120px' },
                                        borderRadius: '8px',
                                        ml: { sm: 1, xs: 0 },
                                    }}
                                    error={Boolean(formik.touched.startTime && formik.errors.startTime)}
                                />
                                <Typography sx={{ alignSelf: 'center' }}>To</Typography>
                                <TextField
                                    type="time"
                                    label="To Time"
                                    value={formik.values.endTime}
                                    onChange={(e) => formik.setFieldValue('endTime', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{
                                        width: { xs: '100%', sm: '120px' },
                                        borderRadius: '8px',
                                    }}
                                    error={Boolean(formik.touched.endTime && formik.errors.endTime)}
                                />
                            </Box>
                        </Box>
                        {formik.touched.endTime && formik.errors.endTime && (
                            <Typography color="error" sx={{ mb: 2 }}>
                                {formik.errors.endTime}
                            </Typography>
                        )}

                        {/* Project Selection */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: -1, width: { xs: '100%', sm: '320px' } }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <WorkIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <FormControl fullWidth margin="normal" sx={{ ml: { sm: 2, xs: 0 } }}>
                                <InputLabel sx={{ background: 'white' }}>Select Your Project</InputLabel>
                                <Select
                                    value={formik.values.project}
                                    onChange={(e) => formik.setFieldValue('project', e.target.value)}
                                    error={Boolean(formik.touched.project && formik.errors.project)}
                                    sx={{ borderRadius: '8px' }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {projectList.map((proj) => (
                                        <MenuItem key={proj.id} value={proj.id.toString()}>
                                            {proj.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.project && formik.errors.project && (
                                    <Typography color="error">{formik.errors.project}</Typography>
                                )}
                            </FormControl>
                        </Box>

                        {/* Project Description (Read-Only) */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 1, width: { xs: '100%', sm: '420px' } }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <DescriptionIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Project Description"
                                value={projectDescription}
                                margin="normal"
                                sx={{ ml: { sm: 2, xs: 0 }, borderRadius: '8px' }}
                                disabled
                            />
                        </Box>

                        {/* Group Selection with Autocomplete for user search */}
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ mb: 1, width: '100%' }}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                            <PeopleIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 1, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <Autocomplete
                                multiple
                                options={userOptions}
                                getOptionLabel={(option) => option.name || option.username}
                                filterSelectedOptions
                                disablePortal
                                value={groupOptions}
                                onChange={(event, newValue) => {
                                    // Ensure no duplicate users are selected
                                    const uniqueUsers = Array.from(new Set(newValue.map((user) => user.id))).map((id) =>
                                        newValue.find((user) => user.id === id)
                                    );
                                    setGroupOptions(uniqueUsers); // Update the groupOptions state with unique users
                                    formik.setFieldValue(
                                        'group',
                                        uniqueUsers.map((user) => user.id)
                                    ); // Update the Formik field with unique user IDs
                                }}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    ml: { sm: 2, xs: 0 },
                                    mr:1,
                                    '& .MuiAutocomplete-inputRoot': {
                                        flexWrap: 'wrap',
                                        height: 'auto',
                                        padding: '4px',
                                    },
                                    '& .MuiChip-root': {
                                        margin: '4px',
                                    },
                                    '& .MuiInputBase-root': {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search and select group members"
                                        onChange={(e) => setSearchQuery(e.target.value)} // Update the search query
                                        sx={{
                                            borderRadius: '8px',
                                            width: '100%',
                                            ml: { sm: 1, xs: 0 },
                                        }}
                                        error={Boolean(formik.touched.group && formik.errors.group)}
                                    />
                                )}
                            />
                            {formik.touched.group && formik.errors.group && (
                                <Typography color="error">{formik.errors.group}</Typography>
                            )}
                        </Box>

                        {/* Independent Description */}
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            sx={{ width: '100%' }}
                        >
                            <DescriptionIcon
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 4, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }}
                            />
                            <TextareaAutosize
                                minRows={4}
                                placeholder="Independent Description"
                                style={{
                                    width: '100%',
                                    ml: { sm: 2, xs: 0 },
                                    padding: '8px',
                                    borderRadius: '8px',
                                    borderColor: '#ccc',
                                    fontFamily: 'inherit',
                                    resize: 'none',
                                }}
                                value={formik.values.independentDescription}
                                onChange={formik.handleChange}
                                name="independentDescription"
                            />
                        </Box>

                        {/* Independent Description */}
                        {/* <Box display="flex" alignItems="center">
                            <DescriptionIcon 
                                sx={{
                                    color: '#5C5C5C',
                                    mr: { sm: 2, xs: 0 },
                                    mb: { xs: 1, sm: 0 },
                                }} 
                            />
                            <TextareaAutosize
                                minRows={4}
                                placeholder="Independent Description"
                                style={{
                                    width: '100%',
                                    ml: { sm: 2, xs: 0 },
                                    padding: '8px',
                                    borderRadius: '8px',
                                    borderColor: '#ccc',
                                    fontFamily: 'inherit',
                                    resize: 'none',
                                }}
                                value={formik.values.independentDescription}
                                onChange={formik.handleChange}
                                name="independentDescription"
                            />
                            {formik.touched.independentDescription && formik.errors.independentDescription && (
                                <Typography color="error">{formik.errors.independentDescription}</Typography>
                            )}
                        </Box> */}

                        {/* Submission Error Message */}
                        {submissionError && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                {submissionError}
                            </Typography>
                        )}
                    </DialogContent>

                    <DialogActions
                        sx={{
                            justifyContent: 'space-between',
                            padding: '16px',
                            mt: -3,
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: 2, sm: 0 },
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#26307A',
                                borderColor: '#26307A',
                                borderRadius: '8px',
                                textTransform: 'none',
                                width: { xs: '100%', sm: 'auto' },
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            width={{ xs: '100%', sm: 'auto' }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    color: 'white',
                                    padding: '8px 16px',
                                    backgroundColor: 'rgb(255,69,0)',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        backgroundColor: 'red',
                                    },
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                                onClick={handleOpenDeleteDialog}
                            >
                                Delete
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="warning"
                                sx={{
                                    backgroundColor: '#E79A2B',
                                    color: 'white',
                                    borderRadius: '8px',
                                    padding: '8px 16px',
                                    textTransform: 'none',
                                    width: { xs: '100%', sm: 'auto' },
                                }}
                                disabled={formik.isSubmitting || loading}
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </Box>
                    </DialogActions>
                </form>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-confirmation-dialog-title"
                aria-describedby="delete-confirmation-dialog-description"
            >
                <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-confirmation-dialog-description">
                        Are you sure you want to delete this booking? This action cannot be undone.
                    </DialogContentText>
                    {deleteError && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {deleteError}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDeleteDialog}
                        variant="outlined"
                        sx={{
                            color: '#26307A',
                            borderColor: '#26307A',
                            borderRadius: '8px',
                            textTransform: 'none',
                        }}
                        disabled={deleteLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteBooking}
                        variant="contained"
                        color="error"
                        sx={{
                            backgroundColor: '#E53E3E',
                            color: 'white',
                            borderRadius: '8px',
                            padding: '8px 16px',
                            textTransform: 'none',
                        }}
                        disabled={deleteLoading}
                    >
                        {deleteLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditWorkbenchBooking;
