import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Paper,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination';
import BookMachineAPIs from 'utilities/api/StudentBookMachine';
import BookableHeader from './BookableHeader';
import BookableListItem from './BookableListItem';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg';
import BookableCards from './ResponsiveCardComponents/BookableCards';

const BookableTab = ({ user }) => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Detects if the screen width is below 600px

  const [selectedItem, setSelectedItem] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;
  const [filteredData, setFilteredData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BookMachineAPIs.ReservedMachineGet();
        const userData = response.reverse().filter((item) => item.reserved_by === user?.id);
        setMachineData(userData);
        setFilteredData(userData);
        const names = [...new Set(userData.map((item) => item.name))];
        const projects = [...new Set(userData.map((item) => item.project_name))];
        const locations = [...new Set(userData.map((item) => item.location))];
        const approvals = [...new Set(userData.map((item) => item.approved))];
        setItemNames(names);
        setProjectNames(projects);
        setLocationNames(locations);
        setApprovalStatus(approvals);
      } catch (error) {
        console.error('Error fetching machine list:', error);
      }
    };

    fetchData();
  }, [user]);

  const handleItemChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = useCallback(() => {
    setFilteredData(
      machineData.filter((item) => {
        return (
          (selectedItem === '' || item.name === selectedItem) &&
          (selectedProject === '' || item.project_name === selectedProject) &&
          (selectedLocation === '' || item.location === selectedLocation) &&
          (selectedStatus === '' || item.approved === selectedStatus) &&
          (searchQuery === '' || item.project_name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
    );
  }, [selectedItem, selectedProject, selectedLocation, selectedStatus, searchQuery, machineData]);

  useEffect(() => {
    filterData();
  }, [selectedItem, selectedProject, selectedLocation, selectedStatus, searchQuery, filterData]);

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / productsPerPage);
  const currentProducts = filteredData.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDelete = (id) => {
    setFilteredData((prevProducts) => prevProducts.filter((product) => product.id !== id));
  };

  const handleEdit = async () => {
    try {
      const response = await BookMachineAPIs.ReservedMachineGet();
      const userData = response.reverse().filter((item) => item.reserved_by === user?.id);
      setMachineData(userData);
      setFilteredData(userData);
    } catch (error) {
      console.error('Error fetching updated machine list:', error);
    }
  };

  return (
    <Box>
      {machineData.length > 0 ? (
        <>
          <div className="headerContainer">
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'center', gap: 1, width: '100%', flexWrap: 'wrap' }}>
              <TextField
                label="Search Projects"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ m: 1, minWidth: 250 }}
              />
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="item-select-label">Select Item</InputLabel>
                <Select labelId="item-select-label" value={selectedItem} onChange={handleItemChange} label="Select Item">
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {itemNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="project-select-label">Select Project</InputLabel>
                <Select labelId="project-select-label" value={selectedProject} onChange={handleProjectChange} label="Select Project">
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {projectNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="location-select-label">Select Location</InputLabel>
                <Select labelId="location-select-label" value={selectedLocation} onChange={handleLocationChange} label="Select Location">
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {locationNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="status-select-label">Select Status</InputLabel>
                <Select labelId="status-select-label" value={selectedStatus} onChange={handleStatusChange} label="Select Status">
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {approvalStatus.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          {isSmallScreen ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {currentProducts.map((item) => (
                <BookableCards key={item.id} item={item} />
              ))}
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ overflowY: 'auto', height: '100%', border: '1px solid black', borderRadius: '20px' }}
            >
              <BookableHeader />
              <div className="inventoryContainer" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                {currentProducts.map((item) => (
                  <BookableListItem key={item.id} product={item} onEdit={handleEdit} onDelete={handleDelete} />
                ))}
              </div>
              {totalPages > 1 && <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />}
            </TableContainer>
          )}
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
          <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
          <Typography variant="h6" color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BookableTab;
