import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  MenuItem,
} from '@mui/material';
import {
  DateRange as DateRangeIcon,
  Assignment as AssignmentIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
  PersonAddAlt as PersonAddAltIcon,
  CreateOutlined as CreateOutlinedIcon,
  Storage as StorageIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import { toast } from 'react-toastify';

const EditPurchaseDialog = ({ open, onClose, product }) => {
  const { access, user } = useSelector((state) => state.user);
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (open && product.inventory) {
      const fetchInventoryDetails = async () => {
        try {
          const details = await InventoryDetailAPI.InventoryDetailGet(access, product.inventory);
          setInventoryDetails(details);
        } catch (error) {
          console.error('Failed to fetch inventory details:', error);
        }
      };
      fetchInventoryDetails();
    }
  }, [open, product.inventory, access]);

  useEffect(() => {
    fetchProjects();
  }, [user]);

  const fetchProjects = async () => {
    try {
      const projectData = await ProjectAPIs.ProjectsGet();
      const filteredProjects = projectData.filter(
        (project) => project.student === user.id || project.teammates.includes(user.id)
      );
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const validationSchema = Yup.object().shape({
    purchase_datetime: Yup.string().required('Purchase date is required'),
    quantity: Yup.number()
      .required('Quantity is required')
      .positive('Quantity must be greater than 0')
      .integer('Quantity must be an integer'),
    project: Yup.string().required('Project is required'),
  });

  const formik = useFormik({
    initialValues: {
      quantity: product.quantity || '',
      project: product.project || '',
      purchase_datetime: product.purchase_datetime || '',
      project_title_display: product.project_title_display || '',
      project_details: product.project_details || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const quantityDifference = values.quantity - product.quantity;

      const updateData = {
        purchased_by: product.purchased_by,
        inventory: product.inventory,
        quantity: values.quantity,
        project: values.project,
        purchase_datetime: values.purchase_datetime,
        project_title_display: values.project_title_display,
        project_details: values.project_details,
      };

      try {
        // Update the purchase with the new quantity
        await PurchaseInventoryAPIs.PurchasedInventoryUpdate(product.id, updateData);
        toast.success('Purchase updated successfully!');

        // Update the inventory to reflect the quantity change
        const newStockAvailable = inventoryDetails.stock_available + quantityDifference; // Note: For purchases, stock increases
        await InventoryDetailAPI.InventoryUpdate(access, product.inventory, { stock_available: newStockAvailable });
        toast.success('Inventory stock updated successfully!');

        onClose();
      } catch (error) {
        toast.error('Failed to update inventory purchase or stock. Please try again.');
        console.error('Error updating inventory purchase or stock:', error);
      }
    },
    enableReinitialize: true,
  });

  const handleDelete = async () => {
    try {
      // Delete the purchase
      await PurchaseInventoryAPIs.PurchasedInventoryDelete(product.id);
      toast.success('Purchase deleted successfully!');

      // Update the inventory to decrement the quantity
      const newStockAvailable = inventoryDetails.stock_available - product.quantity;

      // Update the inventory stock
      await InventoryDetailAPI.InventoryUpdate(access, product.inventory, { stock_available: newStockAvailable });
      toast.success('Inventory stock updated successfully!');

      onClose();
    } catch (error) {
      toast.error('Failed to delete purchase or update inventory. Please try again.');
      console.error('Error deleting purchase or updating inventory:', error);
    }
  };

  const InfoBox = ({ title, content }) => (
    <Typography
      variant="body1"
      sx={{
        color: 'rgb(0,48,68)',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '2',
      }}
    >
      <span style={{ fontWeight: '500' }}>{title}</span>: {content || 'N/A'}
    </Typography>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
          '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '35px',
            border: '1px solid #A6A6A6',
            boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
            maxHeight: '90vh',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            alignItems:{ xs: 'center', md: 'flex-start' },
            overflowY: 'auto',
          }}
        >
          {/* Left Column */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '24px',
              width: { xs: '80%', md: '33.3333%' },
              gap: '24px',
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgb(235,236,250)',
                border: '1px solid rgba(39,63,119,0.4)',
                borderRadius: '20px',
                padding: '24px',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  border: '1px solid rgba(39,63,119,0.7)',
                  borderRadius: '20px',
                }}
                src={inventoryDetails?.image || 'popup.png'}
                alt="Inventory"
              />

              <Typography
                variant="h6"
                sx={{
                  color: 'rgba(39,63,119,0.7)',
                  marginBottom: '20px',
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {inventoryDetails?.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                <InfoBox title="Category" content={inventoryDetails?.category} />
                <InfoBox title="Location" content={inventoryDetails?.location} />
                <InfoBox title="UPC" content={inventoryDetails?.upc} />
                <InfoBox title="Manufacturer" content={inventoryDetails?.manufacturer} />
                <InfoBox title="Description" content={inventoryDetails?.description} />
                <InfoBox title="Purchase Cost" content={inventoryDetails?.purchase_cost} />
              </Box>
            </Box>
          </Box>

          {/* Right Column */}
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '24px',
              width: { xs: '80%', md: '40%' },
              gap: '16px',
              flexShrink: 0,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: '#273F77',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: '600',
                mb: 2,
              }}
            >
              Edit Inventory Purchase
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
              <PersonAddAltIcon sx={{ paddingRight: '10px' }} />
              <Typography
                variant="h6"
                sx={{ color: 'rgb(0,48,68)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}
              >
                {user?.username || ''}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
              <StorageIcon sx={{ color: 'rgb(92,92,92)' }} />
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '500' }}
              >
                Stock Available:
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}
              >
                {inventoryDetails?.stock_available} {inventoryDetails?.stock_unit || ''}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
              <InventoryIcon sx={{ color: 'rgb(92,92,92)' }} />
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '500' }}
              >
                Stock Total:
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}
              >
                {inventoryDetails?.stock_total} {inventoryDetails?.stock_unit || ''}
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <DateRangeIcon />
                <TextField
                  label="Purchase Date"
                  type="date"
                  name="purchase_datetime"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ max: getCurrentDate() }}
                  fullWidth
                  value={formik.values.purchase_datetime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.purchase_datetime && Boolean(formik.errors.purchase_datetime)}
                  helperText={formik.touched.purchase_datetime && formik.errors.purchase_datetime}
                  sx={{ width: 'auto', cursor: 'pointer' }}
                />
              </Box> */}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <AssignmentIcon />
                <TextField
                  label="Quantity"
                  name="quantity"
                  type="number"
                  variant="outlined"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                  sx={{ width: 'auto' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <CreateOutlinedIcon sx={{ color: 'rgb(92,92,92)' }} />
                <TextField
                  name="project"
                  label="Project Title"
                  variant="outlined"
                  select
                  sx={{ width: { xs: '210px', md: '40%' } }}
                  value={formik.values.project}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.project && Boolean(formik.errors.project)}
                  helperText={formik.touched.project && formik.errors.project}
                >
                  <MenuItem value="">Select Project</MenuItem>
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              {/* Additional Fields if Needed */}
              {/* Uncomment if you want to include project title display and details */}
              {/* 
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <EditNoteOutlinedIcon />
                <TextField
                  name="project_title_display"
                  label="Project Title Display"
                  variant="outlined"
                  fullWidth
                  value={formik.values.project_title_display}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.project_title_display && Boolean(formik.errors.project_title_display)}
                  helperText={formik.touched.project_title_display && formik.errors.project_title_display}
                  sx={{ width: '55%' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <EditNoteOutlinedIcon />
                <TextField
                  type="text"
                  name="project_details"
                  label="Project Details"
                  variant="outlined"
                  fullWidth
                  value={formik.values.project_details}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.project_details && Boolean(formik.errors.project_details)}
                  helperText={formik.touched.project_details && formik.errors.project_details}
                />
              </Box>
              */}

              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'flex-end' },
                  mt: 2,
                }}
              >
                <Button
                  onClick={handleDelete}
                  color="primary"
                  sx={{
                    width: '80px',
                    height: '35px',
                    color: 'white',
                    backgroundColor: 'rgb(255,69,0)',
                    border: '1px solid black',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: 'red',
                    },
                  }}
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  sx={{
                    width: '80px',
                    height: '35px',
                    color: 'white',
                    backgroundColor: 'rgb(231,154,43)',
                    border: '1px solid black',
                    borderRadius: '10px',
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default EditPurchaseDialog;
