import React, { useState } from 'react';
import { Paper, TableContainer, Grid, useMediaQuery, useTheme } from '@mui/material';
import TableListHeader from './TableListHeader';
import TableListItem from './TableListItem';
import InventoryResponsiveCard from './InventoryResponsiveCard';
import CustomPagination from '../../Pagination';
import { useSelector } from 'react-redux';
import FilterToolbar from './FilterToolbar';

const InventoryTableView = ({ data, setOpenDialog, searchQuery }) => {
  const { access } = useSelector((state) => state.user);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10); // Default to 10 per page
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const theme = useTheme();
  const isTableView = useMediaQuery(theme.breakpoints.up('md')); // Use table view on medium and larger screens

  // Extract unique tags and categories for the filters
  const uniqueTags = [
    ...new Set(
      data.flatMap((item) => [item.tag_1, item.tag_2, item.tag_3, item.tag_4]).filter((tag) => tag)
    ),
  ];

  const uniqueCategories = [...new Set(data.map((item) => item.category))];

  // Filter products based on selected tags and categories
  const filteredData = data.filter((product) => {
    const tagMatch = selectedTags.length
      ? selectedTags.some((tag) =>
          [product.tag_1, product.tag_2, product.tag_3, product.tag_4].includes(tag)
        )
      : true;
    const categoryMatch = selectedCategories.length
      ? selectedCategories.includes(product.category)
      : true;

    return tagMatch && categoryMatch;
  });

  // Filter products based on search query
  const searchFilteredData = filteredData.filter((product) => {
    const allowedTabs = [product.tag_1, product.tag_2, product.tag_3, product.tag_4].filter(Boolean);
    const query = searchQuery.toLowerCase();
    const { name, category, stock_available } = product;
    const hasMatchingTag = allowedTabs.some((tag) => tag.toLowerCase().includes(query));
    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      hasMatchingTag ||
      (stock_available && stock_available.toString().toLowerCase().includes(query))
    );
  });

  // Calculate total number of pages
  const totalItems = searchFilteredData.length;
  const totalPages = Math.ceil(totalItems / productsPerPage);

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = searchFilteredData.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle tag change
  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === 'string' ? value.split(',') : value);
    setCurrentPage(1); // Reset to first page
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <>
      <FilterToolbar
        uniqueTags={uniqueTags}
        uniqueCategories={uniqueCategories}
        selectedTags={selectedTags}
        selectedCategories={selectedCategories}
        handleTagChange={handleTagChange}
        handleCategoryChange={handleCategoryChange}
      />

      {isTableView ? (
        // Table View for Medium and Larger Screens
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '0.5px solid rgba(181, 181, 181, 1)',
            background:
              'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
            boxShadow: 'none',
            ml: '1rem',
            width: '95%',
            borderRadius: '8px',
            mr: '1rem',
          }}
        >
          <TableListHeader />
          {currentProducts.map((product) => (
            <TableListItem
              key={product.id}
              product={product}
              setOpenDialog={setOpenDialog}
              searchQuery={searchQuery}
            />
          ))}
        </TableContainer>
      ) : (
        // Card View for Small and Medium Screens
        <Grid container spacing={2} sx={{ padding: '16px' }}>
          {currentProducts.map((product) => (
            <Grid item xs={12} sm={6} key={product.id}>
              <InventoryResponsiveCard product={product} searchQuery={searchQuery} />
            </Grid>
          ))}
        </Grid>
      )}

      <CustomPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        productsPerPage={productsPerPage}
        setProductsPerPage={setProductsPerPage}
      />
    </>
  );
};

export default InventoryTableView;
