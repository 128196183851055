import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  MenuItem,
  IconButton,
  Grid,
  useMediaQuery,
} from '@mui/material';
import {
  DateRange as DateRangeIcon,
  PersonAddAlt1 as PersonAddAlt1Icon,
  Warehouse as WarehouseIcon,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
  PersonAddAlt as PersonAddAltIcon,
  Add as AddIcon,
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import BookMachineAPIs from 'utilities/api/StudentBookMachine';
import MachineDetailAPI from 'utilities/api/StudentMachineDetail';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import { useSelector } from 'react-redux';
import CalendarPage from 'components/calendar/calendar';
import AddProjectForm from 'components/ExploreTL/machine/AddProjectForm';
import ImageBaseURL from 'utilities/api/axios';
import { toast } from 'react-toastify';

const MIN_HOURS = 1; // Minimum booking duration in hours
const MAX_HOURS = 5; // Maximum booking duration in hours

const projectTypeChoices = {
  '1': 'Academic',
  '2': 'Research',
  '3': 'Personal',
  '4': 'Other',
};

const validationSchema = Yup.object().shape({
  machine: Yup.string().required('Machine selection is required'),
  reserved_by: Yup.string().required('Reserved By field is required'),
  type_of_project: Yup.string().required('Type of project is required'),
  project: Yup.string().required('Project Title is required'),
  project_details: Yup.string().required('Project Details are required'),
  reserved_date: Yup.string().required('Reserved date is required'),
  time_range: Yup.object().shape({
    from_time: Yup.string()
      .required('Start time is required')
      .test(
        'is-required-if-to_time',
        'Start time is required when End time is provided',
        function (value) {
          const { to_time } = this.parent;
          if (to_time && !value) {
            return this.createError({ path: 'time_range.from_time', message: 'Start time is required' });
          }
          return true;
        }
      ),
    to_time: Yup.string()
      .required('End time is required')
      .test(
        'is-required-if-from_time',
        'End time is required when Start time is provided',
        function (value) {
          const { from_time } = this.parent;
          if (from_time && !value) {
            return this.createError({ path: 'time_range.to_time', message: 'End time is required' });
          }
          return true;
        }
      )
      .test(
        'isAtLeastOneHour',
        `End time must be at least ${MIN_HOURS} hour(s) after Start time`,
        function (value) {
          const { from_time } = this.parent;
          if (from_time && value) {
            const fromTime = new Date(`1970-01-01T${from_time}Z`);
            const toTime = new Date(`1970-01-01T${value}Z`);
            const diffInHours = (toTime - fromTime) / 3600000; // milliseconds to hours
            return diffInHours >= MIN_HOURS;
          }
          return true;
        }
      )
      .test(
        'isAtMostFiveHours',
        `End time must be at most ${MAX_HOURS} hour(s) after Start time`,
        function (value) {
          const { from_time } = this.parent;
          if (from_time && value) {
            const fromTime = new Date(`1970-01-01T${from_time}Z`);
            const toTime = new Date(`1970-01-01T${value}Z`);
            const diffInHours = (toTime - fromTime) / 3600000; // milliseconds to hours
            return diffInHours <= MAX_HOURS;
          }
          return true;
        }
      ),
  }),
});

const BookMachineDialog = ({ open, onClose, machineId }) => {
  const [projects, setProjects] = useState([]);
  const [bookingError, setBookingError] = useState(null);
  const { access, user } = useSelector((state) => state.user);
  const [machineDetails, setMachineDetails] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [isAddProjectDialogOpen, setAddProjectDialogOpen] = useState(false);

  const fullScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (open && machineId) {
      const fetchMachineDetails = async () => {
        try {
          const details = await MachineDetailAPI.MachineDetailGet(access, machineId);
          setMachineDetails(details);
        } catch (error) {
          console.error('Failed to fetch machine details:', error);
        }
      };
      fetchMachineDetails();
    }
  }, [open, machineId, access]);

  useEffect(() => {
    fetchAndSetProjects();
  }, [user.id]);

  const fetchAndSetProjects = async () => {
    try {
      const data = await ProjectAPIs.ProjectsGet();
      const filteredProjects = data.filter(
        (project) => project.student === user.id || project.teammates.includes(user.id)
      );
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const isSlotAvailable = async (machineId, reserved_date, from_time, to_time) => {
    try {
      const bookings = await BookMachineAPIs.ReservedMachineGet();
      const fromDateTime = new Date(`${reserved_date}T${from_time}:00`);
      const toDateTime = new Date(`${reserved_date}T${to_time}:00`);

      const overlappingBookings = bookings.filter((booking) => {
        if (booking.machine !== machineId || booking.reserved_date !== reserved_date) {
          return false;
        }

        if (booking.approved === 'rejected' || booking.approved === 'AutoRejected') {
          return false;
        }

        const bookingStart = new Date(booking.start_time);
        const bookingEnd = new Date(booking.end_time);

        return (
          (fromDateTime < bookingEnd && toDateTime > bookingStart) ||
          (fromDateTime < bookingStart && toDateTime > bookingStart) ||
          (fromDateTime >= bookingStart && fromDateTime < bookingEnd)
        );
      });

      return overlappingBookings.length === 0;
    } catch (error) {
      console.error('Error checking slot availability:', error);
      return false;
    }
  };

  const handleAddProject = async () => {
    await fetchAndSetProjects();
    setAddProjectDialogOpen(false);
  };

  const calculateTimeDifference = (start, end) => {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);
    const startTime = new Date(0, 0, 0, startHour, startMinute, 0);
    const endTime = new Date(0, 0, 0, endHour, endMinute, 0);

    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }

    const diff = (endTime - startTime) / (1000 * 60 * 60); // difference in hours
    return diff;
  };

  const formatDateTime = (date, time) => {
    if (!date || !time) return null;
    return `${date} ${time}:00`;
  };

  const handleCollapsed = () => {
    setCollapsed(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setBookingError(null); // Reset booking error message
    const timeDiff = calculateTimeDifference(values.time_range.from_time, values.time_range.to_time);

    // Additional Validation Checks using defined constants
    if (timeDiff < MIN_HOURS) {
      toast.error(`The difference between start time and end time must be at least ${MIN_HOURS} hour(s).`);
      return;
    }

    if (timeDiff > MAX_HOURS) {
      toast.error(`The difference between start time and end time must not exceed ${MAX_HOURS} hour(s).`);
      return;
    }

    try {
      const startTime = formatDateTime(values.reserved_date, values.time_range.from_time);
      const endTime = formatDateTime(values.reserved_date, values.time_range.to_time);

      if (!startTime || !endTime) {
        toast.error('Invalid date or time format.');
        return;
      }

      const slotAvailable = await isSlotAvailable(
        machineId,
        values.reserved_date,
        values.time_range.from_time,
        values.time_range.to_time
      );

      if (!slotAvailable) {
        toast.error('The selected slot is already booked. Please choose a different time slot.');
        return;
      }

      const formattedValues = {
        reserved_date: values.reserved_date,
        machine: machineId,
        reserved_by: user.id,
        start_time: startTime,
        end_time: endTime,
        duration: timeDiff, // Automatically calculated duration
        type_of_project: values.type_of_project,
        project: values.project,
        project_details: values.project_details,
      };

      await BookMachineAPIs.BookMachineSend(access, formattedValues);

      toast.success('Machine booked successfully!'); // Show success toast
      onClose();
      resetForm(); // Optionally reset the form here if needed
    } catch (error) {
      toast.error('Error booking machine. Please try again.');
      console.error('Error booking machine:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      reserved_by: user?.id || '',
      machine: machineId || '',
      reserved_date: '',
      approved_status: null,
      time_range: { from_time: '', to_time: '' },
      duration: null, // This will be calculated automatically
      type_of_project: '',
      project: '',
      project_details: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleProjectChange = (event) => {
    const selectedProjectId = event.target.value;
    const selectedProject = projects.find((project) => project.id === selectedProjectId);

    formik.setFieldValue('project', selectedProjectId);
    formik.setFieldValue('type_of_project', projectTypeChoices[selectedProject.type]);
    formik.setFieldValue('project_details', selectedProject.description);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const InfoBox = ({ title, content }) => (
    <Typography
      variant="body1"
      sx={{
        color: 'rgb(0,48,68)',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '2',
      }}
    >
      <span style={{ fontWeight: '500' }}>{title}</span>: {content || 'N/A'}
    </Typography>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
        fullScreen={fullScreen}
        sx={{
          overflow:'auto',
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
          '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            margin: fullScreen ? '8px' : '0px',
            borderRadius: fullScreen ? '20px' : '35px',
            border: '1px solid #A6A6A6',
            boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
            // overflow: 'hidden',
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '16px', md: '24px' },
              gap: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgb(235,236,250)',
                height: '100%',
                border: '1px solid rgba(39,63,119,0.4)',
                borderRadius: '20px',
                padding: '24px',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'fill',
                  border: '1px solid rgba(39,63,119,0.7)',
                  borderRadius: '20px',
                }}
                src={
                  machineDetails?.image
                    ? `${ImageBaseURL.defaults.baseURL}${machineDetails.image}`
                    : './machine.png'
                }
                alt={machineDetails?.name}
              />

              <Typography
                variant="h6"
                sx={{
                  color: 'rgba(39,63,119,0.7)',
                  marginBottom: '20px',
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {machineDetails?.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                <InfoBox title="Category" content={machineDetails?.category} />
                <InfoBox title="Location" content={machineDetails?.location} />
                <InfoBox title="UPC" content={machineDetails?.upc} />
                <InfoBox title="Manufacturer" content={machineDetails?.manufacturer} />
                <InfoBox title="Instances" content={machineDetails?.instances} />
                <InfoBox title="Description" content={machineDetails?.description} />
                <InfoBox title="Availability" content={machineDetails?.availability} />
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '16px', md: '24px' },
              gap: '16px',
            }}
          >
            <DialogContent
              sx={{
                padding: 0,
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: '#273F77',
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    fontWeight: '600',
                  }}
                >
                  Book Machine
                </Typography>
                <Button
                  onClick={handleCollapsed}
                  type="button"
                  color="primary"
                  sx={{
                    width: 'auto',
                    height: '35px',
                    color: 'white',
                    backgroundColor: 'blue',
                    border: '1px solid black',
                    borderRadius: '10px',
                    '&:hover': {
                      color: 'black',
                      backgroundColor: 'white',
                    },
                  }}
                >
                  <CalendarTodayIcon />
                  View available slot
                </Button>
              </Box>

              {bookingError && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {bookingError}
                </Typography>
              )}

              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                  <PersonAddAltIcon sx={{ paddingRight: '10px' }} />
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'rgb(0,48,68)',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {user?.username || ''}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                  <DateRangeIcon />
                  <TextField
                    type="date"
                    name="reserved_date"
                    label="Reserved From"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: getCurrentDate() }}
                    fullWidth
                    sx={{ width: 'auto', cursor: 'pointer' }}
                    value={formik.values.reserved_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.reserved_date && Boolean(formik.errors.reserved_date)}
                    helperText={formik.touched.reserved_date && formik.errors.reserved_date}
                  />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                  <AccessTimeIcon />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      flexWrap: { xs: 'wrap', sm: 'nowrap' },
                      width: '100%',
                    }}
                  >
                    <TextField
                      type="time"
                      name="time_range.from_time"
                      label="From Time"
                      value={formik.values.time_range.from_time}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.time_range?.from_time && Boolean(formik.errors.time_range?.from_time)}
                      helperText={formik.touched.time_range?.from_time && formik.errors.time_range?.from_time}
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: { xs: '100%', sm: '160px' } }}
                    />
                    <Typography sx={{ alignSelf: 'center' }}>to</Typography>
                    <TextField
                      type="time"
                      name="time_range.to_time"
                      label="To Time"
                      value={formik.values.time_range.to_time}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.time_range?.to_time && Boolean(formik.errors.time_range?.to_time)}
                      helperText={formik.touched.time_range?.to_time && formik.errors.time_range?.to_time}
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: { xs: '100%', sm: '160px' } }}
                    />
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                  <EditNoteOutlinedIcon />
                  <TextField
                    name="project"
                    label="Project Title"
                    variant="outlined"
                    select
                    fullWidth
                    value={formik.values.project}
                    onChange={handleProjectChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.project && Boolean(formik.errors.project)}
                    helperText={formik.touched.project && formik.errors.project}
                  >
                    {projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.title}
                      </MenuItem>
                    ))}
                  </TextField>
                  <IconButton onClick={() => setAddProjectDialogOpen(true)} color="primary" aria-label="add project">
                    <AddIcon />
                  </IconButton>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                  <AssignmentIcon />
                  <TextField
                    name="type_of_project"
                    label="Type of Project"
                    variant="outlined"
                    fullWidth
                    sx={{ width: '55%' }}
                    value={formik.values.type_of_project}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type_of_project && Boolean(formik.errors.type_of_project)}
                    helperText={formik.touched.type_of_project && formik.errors.type_of_project}
                    disabled
                  />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                  <EditNoteOutlinedIcon />
                  <TextField
                    type="text"
                    name="project_details"
                    label="Project Details"
                    variant="outlined"
                    fullWidth
                    value={formik.values.project_details}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.project_details && Boolean(formik.errors.project_details)}
                    helperText={formik.touched.project_details && formik.errors.project_details}
                    disabled
                  />
                </Box>

                <DialogActions sx={{ padding: 0, mt: 2 }}>
                  <Button
                    onClick={onClose}
                    color="primary"
                    sx={{
                      width: '80px',
                      height: '35px',
                      color: 'black',
                      border: '1px solid rgb(218,218,218)',
                      borderRadius: '10px',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    sx={{
                      width: '80px',
                      height: '35px',
                      color: 'white',
                      backgroundColor: 'rgb(231,154,43)',
                      border: '1px solid black',
                      borderRadius: '10px',
                    }}
                  >
                    Book
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        open={!collapsed}
        onClose={() => setCollapsed(true)}
        fullWidth={true}
        maxWidth="lg"
        // fullScreen={useMediaQuery('(max-width:960px)')}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              backgroundColor: 'white',
              zIndex: 1,
              position: 'relative',
            }}
          >
            <Typography variant="h6">Available Slots</Typography>
            <IconButton onClick={() => setCollapsed(true)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* COVERING THE TOPBAR OF CALENDARPAGE COMPONENT */}
          <Box sx={{ marginTop: '-80px' }}>
            {machineDetails && <CalendarPage setCollapsed={setCollapsed} machineId={machineDetails.id} />}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isAddProjectDialogOpen}
        onClose={() => setAddProjectDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        fullScreen={useMediaQuery('(max-width:600px)')}
      >
        <DialogContent>
          <AddProjectForm onClose={() => setAddProjectDialogOpen(false)} onAddProject={handleAddProject} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookMachineDialog;
