import React, { useState } from 'react';
import { Card, Typography, TextField, Box, Button, Tooltip } from '@mui/material';
import EditMachineDialog from 'components/popups/EditMachineDialog';

const BookableListItem = ({ product, onEdit, onDelete }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    onEdit(); // Notify parent to refresh data
  };

  const getDateTimeFromDateTimeStr = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  const isEditDisabled = () => {
    const currentDateTime = new Date();
    const endDateTime = new Date(product.end_time);
    return currentDateTime > endDateTime;
  };

  return (
    <>
      <Card sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'white',
        height: 'auto',
        boxSizing: 'border-box',
        padding: '0.875rem 0.75rem',
        borderBottom: '1px solid #F2F2F2'
      }}>
        <Typography variant="body2" sx={{
          width: '21%',
          textAlign: 'center',
          color: '#7A7A7A',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {product.name}
        </Typography>
        <Typography variant="body2" sx={{
          width: '16%',
          textAlign: 'center',
          color: '#7A7A7A',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {product.project_name}
        </Typography>
        <Typography variant="body2" sx={{
          width: '12%',
          textAlign: 'center',
          color: '#7A7A7A',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {product.location}
        </Typography>
        <Typography variant="body2" sx={{
          width: '15%',
          textAlign: 'center',
          color: '#7A7A7A',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {getDateTimeFromDateTimeStr(product.start_time)}
        </Typography>
        <Typography variant="body2" sx={{
          width: '15%',
          textAlign: 'center',
          color: '#7A7A7A',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {getDateTimeFromDateTimeStr(product.end_time)}
        </Typography>
        <TextField
          value={product.approved}
          sx={{
            width: '12%',
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '20px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          disabled
        />
        <Box sx={{
          width: '10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight:'-1%'
        }}>
          <Tooltip title={isEditDisabled() ? "Reservation time slot is completed" : ""}>
            <span>
              <Button 
                onClick={handleOpenDialog} 
                variant='text' 
                sx={{
                  backgroundColor: isEditDisabled() ? "#E0E0E0" : "#E79A2B",
                  color: isEditDisabled() ? "#A0A0A0" : "#fff",
                  padding: "8px 32px",
                  borderRadius: "12px",
                  cursor: "pointer",
                  fontWeight: 600,
                  textTransform: 'none',
                }} 
                disabled={isEditDisabled()}
              > 
                Edit 
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Card>
      {openDialog && (
        <EditMachineDialog 
          open={openDialog} 
          onClose={handleCloseDialog} 
          product={product} 
        />
      )}
    </>
  );
};

export default BookableListItem;
