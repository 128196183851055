import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import GridTutorialCard from './GridTutorialCard';
import SearchBar from './SearchBar';
import StudentTutorialAPIs from 'utilities/api/StudentTutorialAPIs';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Import the No Data Found image
import { useSelector } from 'react-redux';

const TutorialContent = () => {
    const { user } = useSelector((state) => state.user);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const moduleList = await StudentTutorialAPIs.ModuleListGet();
                setData(moduleList.reverse());
            } catch (error) {
                console.error('Failed to fetch module list:', error);
            }
        };

        fetchData();
    }, [user]);

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter(item => {
        const name = item.name?.toLowerCase() || '';
        const description = item.description?.toLowerCase() || '';
        const query = searchQuery.toLowerCase();

        return name.includes(query) || description.includes(query);
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {filteredData.length > 0 ? (
                <>
                    <SearchBar searchQuery={searchQuery} handleSearchQueryChange={handleSearchQueryChange} />
                    <Box
                        sx={{
                            display: 'grid',
                            // gridTemplateColumns: 'repeat(4, 1fr)',
                            gridTemplateColumns: {
                                xs: 'repeat(1, 1fr)', // 1 column on extra-small screens
                                sm: 'repeat(2, 1fr)', // 2 columns on small screens
                                md: 'repeat(3, 1fr)', // 3 columns on medium screens
                                lg: 'repeat(4, 1fr)', // 4 columns on large screens and above
                            },
                            gap: '20px',
                        }}
                    >
                        {filteredData.map((item) => (
                            <React.Fragment key={item.id}>
                                <GridTutorialCard item={item} />
                            </React.Fragment>
                        ))}
                    </Box>
                </>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
            {/* Dialog component can be added here if needed */}
        </Box>
    );
};

export default TutorialContent;
