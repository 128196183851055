import React, { useState } from "react";
import { Paper, TableContainer, useMediaQuery, useTheme, Box } from "@mui/material";
import TableHeaders from "./TableHeaders";
import CustomPagination from "../../../components/Pagination";
import TableContent from "./TableContent";
import CompletedWorkshopCards from "./CompletedWorkshopCards"; // Import the new card component

const WorkshopContent = ({ workshopData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5 for demonstration

  // Material-UI theme for media query
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (sm or smaller)

  // Calculate total number of pages
  const totalItems = workshopData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get current items for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = workshopData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change for pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      {/* Render CompletedWorkshopCards for small screens */}
      {isSmallScreen ? (
        <div>
          {currentItems.map((item, index) => (
            <CompletedWorkshopCards key={index} item={item} />
          ))}
          {/* Pagination */}
          <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        // Desktop view: Table layout with pagination
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '0.5px solid rgba(181, 181, 181, 1)',
            background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
            boxShadow: 'none',
            maxWidth: '100%',
            borderRadius: '8px',
          }}
        >
          <TableHeaders />
          {currentItems.map((item, index) => (
            <TableContent key={index} item={item} />
          ))}
          {/* Pagination */}
          <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </TableContainer>
      )}

      {/* Pagination */}
      {/* <CustomPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      /> */}
    </Box>
  );
};

export default WorkshopContent;
