import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import ListVideoContainer from "./ListVideoContainer"; // Adjust the import path as necessary
import {
  setOnDetail,
  onTutorialPage,
} from "../../../redux/slices/tutorialSlice";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { ArrowBack } from "@mui/icons-material";
import StudentTutorialAPIs from "utilities/api/StudentTutorialAPIs";

const CoursePage = ({ tutorialID }) => {
  const dispatch = useDispatch();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoDetails, setVideoDetails] = useState([]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const courseData = await StudentTutorialAPIs.ModuleDetailGet(
          tutorialID
        );
        setCourse(courseData);

        const videoPromises = courseData.videos.map((video) =>
          StudentTutorialAPIs.VideoDetailGet(video.video)
        );
        const videoDetails = await Promise.all(videoPromises);
        setVideoDetails(videoDetails);
      } catch (error) {
        console.error("Failed to fetch course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [tutorialID]);

  const handleWatchNowClick = () => {
    dispatch(onTutorialPage(true));
  };

  const handleDetailClick = () => {
    dispatch(setOnDetail(course));
  };

  if (loading) {
    return (
      <Container
        sx={{
          padding: { xs: "16px", md: "20px" }, // Responsive padding
        }}
      >
        <Typography
          variant="h4"
          align="left"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive font size
          }}
        >
          Loading...
        </Typography>
      </Container>
    );
  }

  if (!course) {
    return (
      <Container
        sx={{
          padding: { xs: "16px", md: "20px" }, // Responsive padding
        }}
      >
        <Typography
          variant="h4"
          align="left"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive font size
          }}
        >
          Course not found
        </Typography>
      </Container>
    );
  }

  // Calculate total minutes
  const totalMinutes = videoDetails.length * 10; // Assuming each video is approximately 10 minutes

  return (
    <Container
      // sx={{
      //   padding: { xs: "16px" }, // Responsive padding
      // }}
      sx={{ 
        // margin: "0 -24px",
        margin: { xs: "0", md: "0 -24px" }, // Responsive margin
        padding: "20px"
      }}
    >
      {/* Course Name and Back Button */}
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <IconButton
            onClick={handleDetailClick}
            size="large"
            aria-label="back"
          >
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Typography
            variant="h4"
            align="left"
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive font size
              wordBreak: "break-word",
            }}
          >
            {course.name}
          </Typography>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box
        sx={{
          margin: "20px 0",
        }}
      >
        <Grid container spacing={2} direction={{ xs: "column", sm: "row" }}>
          <Grid item xs={12} sm="auto">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<PlayArrowIcon />}
              sx={{
                textTransform: "none",
                backgroundColor: "#E79A2B",
                color: "#FFF",
                "&:hover": {
                  backgroundColor: "#f9c346",
                },
              }}
              onClick={handleWatchNowClick}
            >
              Watch now
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              fullWidth
              variant="outlined"
              startIcon={<PlaylistAddIcon />}
              sx={{
                textTransform: "none",
                borderColor: "#000",
                color: "#000",
                "&:hover": {
                  borderColor: "#000",
                },
              }}
            >
              Add to watchlist
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* About the Course */}
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          sx={{
            fontSize: { xs: "1.3rem", md: "1.5rem" }, // Responsive font size
            marginBottom: "8px",
          }}
        >
          About the Course
        </Typography>
        <Typography
          variant="body2"
          align="left"
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" }, // Responsive font size
            marginBottom: "16px",
          }}
        >
          {course.description}
        </Typography>
      </Box>

      {/* Video Courses */}
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          sx={{
            fontSize: { xs: "1.3rem", md: "1.5rem" }, // Responsive font size
            marginBottom: "8px",
          }}
        >
          Video Courses
          <span
            style={{
              marginLeft: "5px",
              fontSize: "0.9rem",
              fontWeight: "normal",
            }}
          >
            ( {videoDetails.length} videos, {(totalMinutes / 60).toFixed(2)}{" "}
            hours )
          </span>
        </Typography>
      </Box>

      {/* Video List */}
      <ListVideoContainer videos={videoDetails} />
    </Container>
  );
};

export default CoursePage;
