import React from 'react';
import {
  Drawer,
  List,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/slices/userSlice';
import logo from '../utilities/dummy_assets/mapITlogo.png';
import SchoolIcon from '@mui/icons-material/School';

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const isPathActive = (path) => {
    return window.location.pathname === path;
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  const SidebarButton = ({ path, icon, title }) => (
    <Button
      component={RouterLink}
      to={path}
      variant="contained"
      title={title}
      className={`sidebarButton ${isPathActive(path) ? 'activeSidebarButton' : ''}`}
      sx={{ marginLeft: '5px' }}
      onClick={onClose} // Close Sidebar after navigation
    >
      {icon}
    </Button>
  );

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // Adjust drawerWidth for small screens
  const drawerWidth = isSmallScreen ? 110 : 118;

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflow: 'hidden',
          backgroundColor: '#F2F3FC',
          border: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100vh',
        },
      }}
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      anchor="left"
      open={isSmallScreen ? open : undefined}
      onClose={isSmallScreen ? onClose : undefined}
    >
      <div>
        <List className="drawer">
          <Stack direction="column" spacing={2}>
            <div className="sidebarTop">
              <RouterLink to="/dashboard">
                <img src="./MBF.png" alt="Company Logo" />
              </RouterLink>
            </div>
            {user.TL_name === 'Org-Admin' ? (
              <>
                <Button
                  variant="contained"
                  title="Settings"
                  className="sidebarButton"
                  sx={{ marginLeft: '5px' }}
                  onClick={onClose}
                >
                  <SettingsOutlinedIcon />
                </Button>
                <Button
                  onClick={() => {
                    handleLogout();
                    onClose();
                  }}
                  variant="contained"
                  title="Logout"
                  className="sidebarButton"
                  sx={{ marginLeft: '5px' }}
                >
                  <LogoutOutlinedIcon />
                </Button>
              </>
            ) : (
              <>
                <SidebarButton path="/dashboard" icon={<DashboardOutlinedIcon />} title="Dashboard" />
                <SidebarButton path="/explore" icon={<LanguageOutlinedIcon />} title="Explore" />
                <SidebarButton path="/projects" icon={<FolderOpenOutlinedIcon />} title="Projects" />
                <SidebarButton path="/records" icon={<StoreOutlinedIcon />} title="Records" />
                <SidebarButton path="/training" icon={<SchoolIcon />} title="Training" />
                <SidebarButton path="/settings" icon={<SettingsOutlinedIcon />} title="Settings" />
                <Button
                  onClick={() => {
                    handleLogout();
                    onClose();
                  }}
                  variant="contained"
                  title="Logout"
                  className="sidebarButton"
                  sx={{ marginLeft: '5px' }}
                >
                  <LogoutOutlinedIcon />
                </Button>
              </>
            )}
          </Stack>
        </List>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <img src={logo} alt="Company Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </div>
    </Drawer>
  );
};

export default Sidebar;
