import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import BookMachineDialog from '../popups/BookMachine';
import CardListViewComponent from './machine/CardListViewComponent';
import CardComponent from './machine/CardComponent';
import EquipmentCardView from './equipments/CardComponent';
import EquipmentTableView from './equipments/EquipmentsTableView';
import InventoryTableView from './Inventory/InventoryTableView';

const Content = ({ data, activeCategory, activeView, searchQuery, setSearch }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMachineId, setSelectedMachineId] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Small screen sizes
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Tablet sizes

    const handleOpenDialog = (id) => {
        setSelectedMachineId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSearchQueryChange = (query) => {
        setSearch(query);
    };

    const renderTable = () => {
        switch (activeCategory) {
            case 0:
            case 1:
                return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery} />;
            case 2:
                return <InventoryTableView data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery} />;
            default:
                return null;
        }
    };

    const renderComponent = (item) => {
        switch (activeCategory) {
            case 0:
                return !activeView ? (
                    <CardComponent key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                ) : (
                    <CardListViewComponent key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                );
            case 1:
                return !activeView ? (
                    <EquipmentCardView key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                ) : (
                    <EquipmentTableView key={item.id} data={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                );
            default:
                return null;
        }
    };

    const isCardLayout = activeCategory === 0 || (activeCategory === 1 && !activeView);

    // Adjust grid columns based on screen size
    const gridTemplateColumns = isCardLayout
        ? isMobile
            ? 'repeat(1, 1fr)'
            : isTablet
            ? 'repeat(2, 1fr)'
            : activeCategory === 1
            ? 'repeat(4, 24.5%)'
            : activeView
            ? 'repeat(1, 1fr)'
            : 'repeat(3, 35.5%)'
        : 'none';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
                sx={{
                    display: isCardLayout ? 'grid' : 'block',
                    gridTemplateColumns: isCardLayout ? gridTemplateColumns : 'none',
                    gap: isCardLayout ? '20px' : 'none',
                    alignSelf: (isMobile && isCardLayout) ? 'center' : '',
                }}
            >
                {isCardLayout
                    ? data.map((item, index) => renderComponent(item, index))
                    : renderTable()}
            </Box>
            <BookMachineDialog open={openDialog} onClose={handleCloseDialog} machineId={selectedMachineId} />
        </Box>
    );
};

export default Content;
