import React, { useState, useEffect, useMemo } from 'react';
import {
    TableContainer,
    Paper,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    Typography,
    useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import PurchasableHeader from './PurchasableHeader';
import PurchasableListItem from './PurchasableListItem';
import PurchaseCards from './ResponsiveCardComponents/PurchaseCards';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg';

const PurchasableTab = ({ user }) => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Detect small screens

    const [selectedItem, setSelectedItem] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedPickup, setSelectedPickup] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;
    const [machineData, setMachineData] = useState([]);
    const [itemNames, setItemNames] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [pickups, setPickups] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await PurchaseInventoryAPIs.PurchasedInventoryGet();
                const userData = response.reverse().filter((item) => item.purchased_by === user?.id);
                setMachineData(userData);
                setItemNames([...new Set(userData.map((item) => item.name))]);
                setProjectNames([...new Set(userData.map((item) => item.project_name))]);
                setPickups([...new Set(userData.map((item) => item.pickup_))]);
            } catch (error) {
                console.error('Error fetching machine list:', error);
            }
        };

        fetchData();
    }, [user]);

    const handleItemChange = (event) => setSelectedItem(event.target.value);
    const handleProjectChange = (event) => setSelectedProject(event.target.value);
    const handlePickupChange = (event) => setSelectedPickup(event.target.value);
    const handleSearchChange = (event) => setSearchQuery(event.target.value);

    const filteredData = useMemo(() => {
        return machineData.filter((item) => {
            return (
                (selectedItem === '' || item.name === selectedItem) &&
                (selectedProject === '' || item.project_name === selectedProject) &&
                (selectedPickup === '' || item.pickup_ === selectedPickup) &&
                (searchQuery === '' || item.project_name.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        });
    }, [selectedItem, selectedProject, selectedPickup, searchQuery, machineData]);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / productsPerPage);
    const currentProducts = filteredData.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const handlePageChange = (event, value) => setCurrentPage(value);

    const handleDelete = (id) => setMachineData((prev) => prev.filter((product) => product.id !== id));

    return (
        <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            {machineData.length > 0 ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
                        <TextField
                            label="Search Projects"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}
                        />
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="item-select-label">Select Item</InputLabel>
                            <Select
                                labelId="item-select-label"
                                value={selectedItem}
                                onChange={handleItemChange}
                                label="Select Item"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {itemNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="project-select-label">Select Project</InputLabel>
                            <Select
                                labelId="project-select-label"
                                value={selectedProject}
                                onChange={handleProjectChange}
                                label="Select Project"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {projectNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="pickup-select-label">Select Pickup Status</InputLabel>
                            <Select
                                labelId="pickup-select-label"
                                value={selectedPickup}
                                onChange={handlePickupChange}
                                label="Select Pickup Status"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {pickups.map((pickup) => (
                                    <MenuItem key={pickup} value={pickup}>
                                        {pickup}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {isSmallScreen ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                            {currentProducts.map((item) => (
                                <PurchaseCards key={item.id} item={item} onDelete={handleDelete} />
                            ))}
                        </Box>
                    ) : (
                        <TableContainer
                            component={Paper}
                            elevation={0}
                            sx={{ overflowY: 'auto', mt: 2, border: '1px solid black', borderRadius: '20px' }}
                        >
                            <PurchasableHeader />
                            <Box className="inventoryContainer" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                {currentProducts.map((item) => (
                                    <PurchasableListItem key={item.id} product={item} onDelete={handleDelete} />
                                ))}
                            </Box>
                            {totalPages > 1 && (
                                <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                            )}
                        </TableContainer>
                    )}
                </>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default PurchasableTab;
