import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogActions, Button, TextField, Typography, Box, MenuItem
} from '@mui/material';
import {
  PersonAddAlt as PersonAddAltIcon,
  Assignment as AssignmentIcon,
  DateRange as DateRangeIcon,
  CreateOutlined as CreateOutlinedIcon,
  Storage as StorageIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import { toast } from 'react-toastify';

const EditIssueDialog = ({ open, onClose, product }) => {
  const { access, user } = useSelector((state) => state.user);
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (open && product.inventory) {
      const fetchInventoryDetails = async () => {
        try {
          const details = await InventoryDetailAPI.InventoryDetailGet(access, product.inventory);
          setInventoryDetails(details);
        } catch (error) {
          console.error('Failed to fetch inventory details:', error);
        }
      };
      fetchInventoryDetails();
    }
  }, [open, product.inventory, access]);

  useEffect(() => {
    fetchProjects();
  }, [user]);

  const fetchProjects = async () => {
    try {
      const projectData = await ProjectAPIs.ProjectsGet();
      const filteredProjects = projectData.filter(
        (project) => project.student === user.id || project.teammates.includes(user.id)
      );
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const validationSchema = Yup.object().shape({
    issued_from: Yup.string().required('Issue start date is required'),
    issued_till: Yup.string()
      .required('Issue end date is required')
      .test('is-after', 'Issue end date must be after start date', function (value) {
        const { issued_from } = this.parent;
        return new Date(value) > new Date(issued_from);
      }),
    quantity: Yup.number()
      .required('Quantity is required')
      .positive('Quantity must be greater than 0')
      .integer('Quantity must be an integer')
      .test('stock-availability', 'Not enough stock available', function (value) {
        const quantityDifference = value - product.quantity;
        const newStockAvailable = inventoryDetails?.stock_available - quantityDifference;
        return newStockAvailable >= 0;
      }),
    project: Yup.string().required('Project is required'),
  });

  const formik = useFormik({
    initialValues: {
      issued_from: product.issued_from || '',
      issued_till: product.issued_till || '',
      quantity: product.quantity || '',
      project: product.project || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const quantityDifference = values.quantity - product.quantity;

      const updateData = {
        issued_from: values.issued_from,
        issued_till: values.issued_till,
        quantity: values.quantity,
        pickup: product.pickup,
        inventory: product.inventory,
        issued_by: product.issued_by,
        project: values.project,
      };

      const newStockAvailable = inventoryDetails.stock_available - quantityDifference;

      if (newStockAvailable < 0) {
        toast.error('Not enough stock available.');
        return;
      }

      try {
        // Update the issue with the new quantity
        await IssueInventoryAPIs.IssueInventoryUpdate(product.id, updateData);
        toast.success('Inventory issue updated successfully!');

        // Update the inventory to reflect the quantity change
        await InventoryDetailAPI.InventoryUpdate(access, product.inventory, { stock_available: newStockAvailable });
        toast.success('Stock updated successfully!');

        onClose();
      } catch (error) {
        toast.error('Failed to update inventory issue or stock. Please try again.');
        console.error('Error updating inventory issue or stock:', error);
      }
    },
    enableReinitialize: true,
  });

  const handleDelete = async () => {
    try {
      // Delete the issue
      await IssueInventoryAPIs.IssueInventoryDelete(product.id);
      toast.success('Issue deleted successfully!');

      // Update the inventory to increment the quantity
      const newStockAvailable = inventoryDetails.stock_available + product.quantity;

      // Update the inventory stock
      await InventoryDetailAPI.InventoryUpdate(access, product.inventory, { stock_available: newStockAvailable });
      toast.success('Inventory stock updated successfully!');

      onClose();
    } catch (error) {
      toast.error('Failed to delete issue or update inventory. Please try again.');
      console.error('Error deleting issue or updating inventory:', error);
    }
  };

  const InfoBox = ({ title, content }) => (
    <Typography
      variant="body1"
      sx={{
        color: 'rgb(0,48,68)',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '2',
      }}
    >
      <span style={{ fontWeight: '500' }}>{title}</span>: {content || 'N/A'}
    </Typography>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
          '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '35px',
            border: '1px solid #A6A6A6',
            boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
            maxHeight: '90vh',
          },
          alignItems:{ xs: 'center', md: 'flex-start' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            // width: '100%',
            // width: { xs: '80%', md: '100%' },
            overflowY: 'auto',
          }}
        >
          {/* Left Column */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '24px',
              // width: { xs: '100%', md: '33.3333%' },
              width: { xs: '80%', md: '33.3333%' },
              gap: '24px',
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgb(235,236,250)',
                border: '1px solid rgba(39,63,119,0.4)',
                borderRadius: '20px',
                padding: '24px',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  border: '1px solid rgba(39,63,119,0.7)',
                  borderRadius: '20px',
                }}
                src={inventoryDetails?.image || 'popup.png'}
                alt="Inventory"
              />

              <Typography
                variant="h6"
                sx={{
                  color: 'rgba(39,63,119,0.7)',
                  marginBottom: '20px',
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {inventoryDetails?.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                <InfoBox title="Category" content={inventoryDetails?.category} />
                <InfoBox title="Location" content={inventoryDetails?.location} />
                <InfoBox title="UPC" content={inventoryDetails?.upc} />
                <InfoBox title="Manufacturer" content={inventoryDetails?.manufacturer} />
                <InfoBox title="Description" content={inventoryDetails?.description} />
                <InfoBox title="Purchase Cost" content={inventoryDetails?.purchase_cost} />
              </Box>
            </Box>
          </Box>

          {/* Right Column */}
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '24px',
              width: { xs: '80%', md: '40%' },
              gap: '16px',
              flexShrink: 0,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: '#273F77',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontWeight: '600',
                mb: 2,
              }}
            >
              Edit Inventory Issue
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 0 }}>
              <PersonAddAltIcon sx={{ paddingRight: '10px' }} />
              <Typography
                variant="h6"
                sx={{ color: 'rgb(0,48,68)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}
              >
                {user?.username || ''}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <StorageIcon sx={{ color: 'rgb(92,92,92)' }} />
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '500' }}
              >
                Stock Available:
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}
              >
                {inventoryDetails?.stock_available}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 0 }}>
              <InventoryIcon sx={{ color: 'rgb(92,92,92)' }} />
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '500' }}
              >
                Stock Total:
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}
              >
                {inventoryDetails?.stock_total}
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <DateRangeIcon />
                <TextField
                  type="date"
                  name="issued_from"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: getCurrentDate() }}
                  fullWidth
                  sx={{ width: 'auto', cursor: 'pointer' }}
                  value={formik.values.issued_from}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.issued_from && Boolean(formik.errors.issued_from)}
                  helperText={formik.touched.issued_from && formik.errors.issued_from}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <DateRangeIcon />
                <TextField
                  type="date"
                  name="issued_till"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: formik.values.issued_from || getCurrentDate() }}
                  fullWidth
                  sx={{ width: 'auto', cursor: 'pointer' }}
                  value={formik.values.issued_till}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.issued_till && Boolean(formik.errors.issued_till)}
                  helperText={formik.touched.issued_till && formik.errors.issued_till}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <AssignmentIcon />
                <TextField
                  label="Quantity"
                  name="quantity"
                  type="number"
                  variant="outlined"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                  sx={{ width: 'auto' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
                <CreateOutlinedIcon sx={{ color: 'rgb(92,92,92)' }} />
                <TextField
                  name="project"
                  label="Project Title"
                  variant="outlined"
                  select
                  sx={{ width: { xs: '210px', md: '40%' } }}
                  value={formik.values.project}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.project && Boolean(formik.errors.project)}
                  helperText={formik.touched.project && formik.errors.project}
                >
                  <MenuItem value="">Select Project</MenuItem>
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'flex-end' },
                }}
              >
                <Button
                  onClick={handleDelete}
                  color="primary"
                  sx={{
                    width: '80px',
                    height: '35px',
                    color: 'white',
                    backgroundColor: 'rgb(255,69,0)',
                    border: '1px solid black',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: 'red',
                    },
                  }}
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  sx={{
                    width: '80px',
                    height: '35px',
                    color: 'white',
                    backgroundColor: 'rgb(231,154,43)',
                    border: '1px solid black',
                    borderRadius: '10px',
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default EditIssueDialog;
