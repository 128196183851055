import React, { useState } from "react";
import { Card, Typography, Box, Button, Tooltip } from "@mui/material";
import EditPurchaseDialog from "components/popups/EditPurchaseDialog";

const IssueCards = ({ item, onEdit }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (onEdit) {
      onEdit(); // Notify parent to refresh data
    }
  };

  const getDateTimeFromDateTimeStr = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  const isEditDisabled = () => {
    const currentDateTime = new Date();
    const endDateTime = new Date(item.end_time);
    return currentDateTime > endDateTime;
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #E0E0E0",
          borderRadius: "8px",
          boxShadow: "none",
          marginBottom: "16px",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
            paddingInline: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              color: "#333",
              fontSize: "16px", // Smaller font size for the header
            }}
          >
            {item.name || "Item Name"}
          </Typography>
          <Box
          sx={{
            mt:1
          }}
          >
            <Tooltip
              title={isEditDisabled() ? "Reservation time slot is completed" : ""}
            >
              <span>
                <Button
                  onClick={handleOpenDialog}
                  variant="text"
                  sx={{
                    backgroundColor: isEditDisabled() ? "#E0E0E0" : "#E79A2B",
                    color: isEditDisabled() ? "#A0A0A0" : "#fff",
                    padding: "4px 12px",
                    borderRadius: "8px",
                    fontWeight: 600,
                    textTransform: "none",
                  }}
                  disabled={isEditDisabled()}
                >
                  Edit
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>

        {/* Project Section */}
        <Typography
          variant="body2"
          sx={{
            paddingInline: 1,
            color: "#666",
            marginBottom: "16px",
            fontSize: "14px", // Adjusted font size for description
            whiteSpace: "nowrap", // Prevents line breaks
            overflow: "hidden", // Truncates long text
            textOverflow: "ellipsis", // Adds "..." for overflow text
          }}
        >
          {`Project Name: ${item.project_name}`}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            paddingInline: 1,
            color: "#666",
            marginBottom: "16px",
            fontSize: "14px", // Adjusted font size for description
            whiteSpace: "nowrap", // Prevents line breaks
            overflow: "hidden", // Truncates long text
            textOverflow: "ellipsis", // Adds "..." for overflow text
          }}
        >
          {`Purchase Date: ${getDateTimeFromDateTimeStr(item.purchase_datetime)}`}
        </Typography>

        {/* Details Section */}
        <Box
          sx={{
            backgroundColor: "#F8F8F8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: "16px", // Spacing between columns
            flexWrap: "wrap",
            rowGap: "8px", // Handles wrapping
            border: "1px solid #E0E0E0",
            borderRadius: "0px 0px 8px 8px",
            textAlign: "center",
          }}
        >
            
          <Box
            sx={{
              flex: 1,
              padding: 1,
              borderRight: "1px solid #E0E0E0",
              "&:last-child": {
                borderRight: "none",
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#999",
                fontWeight: "500",
                textTransform: "uppercase",
                fontSize: "10px",
              }}
            >
              Quantity
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              {item.quantity}
            </Typography>
          </Box>
          
          <Box
            sx={{
              flex: 1,
              borderRight: "1px solid #E0E0E0",
              "&:last-child": {
                borderRight: "none",
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#999",
                fontWeight: "500",
                textTransform: "uppercase",
                fontSize: "10px",
              }}
            >
              Picked
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              {item.pickup_}
            </Typography>
          </Box>
          
          <Box
            sx={{
              flex: 1,
              padding: 1,
              borderRight: "1px solid #E0E0E0",
              "&:last-child": {
                borderRight: "none",
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#999",
                fontWeight: "500",
                textTransform: "uppercase",
                fontSize: "10px",
              }}
            >
              Amount
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#333",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              {item.purchase_amount}
            </Typography>
          </Box>
        </Box>
      </Card>

      {openDialog && (
        <EditPurchaseDialog
          open={openDialog}
          onClose={handleCloseDialog}
          product={item}
        />
      )}
    </>
  );
};

export default IssueCards;
