import React from "react";
import { Card, Typography, Box, Button, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CompletedWorkshopCards = ({ item }) => {
  const buttonStyles = {
    display: "flex",
    width: "100%",
    padding: "0px",
    margin: "0px",
    minWidth: "0px",
    color: "#273F77",
    backgroundColor: "transparent",
    boxShadow: "0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  const handleOpenDialog = () => {
    // Logic for opening dialog or handling button actions
  };

  const handleOverflow = (event) => {
    const element = event.target;
    element.style.maxHeight = "2rem";
    element.style.overflow = "hidden";
    element.style.textOverflow = "ellipsis";
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        boxShadow: "none",
        marginBottom: "16px",
        backgroundColor: "#fff",
        position: "relative",
      }}
    >
      {/* Title Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingInline: 1,
          paddingTop: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            color: "#333",
            fontSize: "16px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item.title || "Title"}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Tooltip title="View Details">
            <span>
              <Button
                variant="text"
                sx={{
                  backgroundColor: "#E79A2B",
                  color: "#fff",
                  padding: "4px 12px",
                  borderRadius: "8px",
                  fontWeight: 600,
                  textTransform: "none",
                }}
                onClick={handleOpenDialog}
              >
                <VisibilityIcon sx={{ color: "#fff" }} />
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>

      {/* Description Section */}
      <Typography
        variant="body2"
        sx={{
          paddingInline: 1,
          color: "#666",
          marginBottom: "8px",
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {item.description || "Description"}
      </Typography>

      {/* Date & Time Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: 1,
          backgroundColor: "#F8F8F8",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#333",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          {`${item.from_date} ${item.from_time} - ${item.to_date} ${item.to_time}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#333",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          {item.attendees.length}
        </Typography>
      </Box>
    </Card>
  );
};

export default CompletedWorkshopCards;
