import React, { useState, useEffect, useCallback } from 'react';
import {
    TableContainer,
    Paper,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    Typography,
    useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import IssuableHeader from './IssuableHeader';
import IssuableListItem from './IssuableListItem';
import IssueCards from './ResponsiveCardComponents/IssueCards';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg';

const IssuableTab = ({ user }) => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Detect small screens

    const [selectedItem, setSelectedItem] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState('');
    const [selectedPickupStatus, setSelectedPickupStatus] = useState('');
    const [selectedReturnedStatus, setSelectedReturnedStatus] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;
    const [filteredData, setFilteredData] = useState([]);
    const [machineData, setMachineData] = useState([]);
    const [itemNames, setItemNames] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [pickupStatus, setPickupStatus] = useState([]);
    const [returnedStatus, setReturnedStatus] = useState([]);
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await IssueInventoryAPIs.IssuedInventoryGet();
                const userData = response.reverse().filter((item) => item.issued_by === user?.id);
                setMachineData(userData);
                setFilteredData(userData);
                const items = [...new Set(userData.map((item) => item.name))];
                const projects = [...new Set(userData.map((item) => item.project_name))];
                const quantities = [...new Set(userData.map((item) => item.quantity))];
                const pickups = [...new Set(userData.map((item) => item.pickup_))];
                const returns = [...new Set(userData.map((item) => item.returned_))];
                setItemNames(items);
                setProjectNames(projects);
                setQuantities(quantities);
                setPickupStatus(pickups);
                setReturnedStatus(returns);
            } catch (error) {
                console.error('Error fetching issued inventory:', error);
            }
        };

        fetchData();
    }, [user, refreshData]);

    const handleItemChange = (event) => setSelectedItem(event.target.value);
    const handleProjectChange = (event) => setSelectedProject(event.target.value);
    const handleQuantityChange = (event) => setSelectedQuantity(event.target.value);
    const handlePickupStatusChange = (event) => setSelectedPickupStatus(event.target.value);
    const handleReturnedStatusChange = (event) => setSelectedReturnedStatus(event.target.value);
    const handleSearchChange = (event) => setSearchQuery(event.target.value);

    const filterData = useCallback(() => {
        setFilteredData(
            machineData.filter((item) => {
                return (
                    (selectedItem === '' || item.name === selectedItem) &&
                    (selectedProject === '' || item.project_name === selectedProject) &&
                    (selectedQuantity === '' || item.quantity === selectedQuantity) &&
                    (selectedPickupStatus === '' || item.pickup_ === selectedPickupStatus) &&
                    (selectedReturnedStatus === '' || item.returned_ === selectedReturnedStatus) &&
                    (searchQuery === '' || item.project_name.toLowerCase().includes(searchQuery.toLowerCase()))
                );
            })
        );
    }, [
        selectedItem,
        selectedProject,
        selectedQuantity,
        selectedPickupStatus,
        selectedReturnedStatus,
        searchQuery,
        machineData,
    ]);

    useEffect(() => {
        filterData();
    }, [selectedItem, selectedProject, selectedQuantity, selectedPickupStatus, selectedReturnedStatus, searchQuery, filterData]);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / productsPerPage);
    const currentProducts = filteredData.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const handlePageChange = (event, value) => setCurrentPage(value);
    const handleDelete = (id) => setFilteredData((prev) => prev.filter((product) => product.id !== id));
    const triggerDataRefresh = () => setRefreshData((prev) => !prev);

    return (
        <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            {machineData.length > 0 ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
                        <TextField
                            label="Search Projects"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}
                        />
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="item-select-label">Select Item</InputLabel>
                            <Select
                                labelId="item-select-label"
                                value={selectedItem}
                                onChange={handleItemChange}
                                label="Select Item"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {itemNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="project-select-label">Select Project</InputLabel>
                            <Select
                                labelId="project-select-label"
                                value={selectedProject}
                                onChange={handleProjectChange}
                                label="Select Project"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {projectNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="pickup-status-label">Select Pickup Status</InputLabel>
                            <Select
                                labelId="pickup-status-label"
                                value={selectedPickupStatus}
                                onChange={handlePickupStatusChange}
                                label="Select Pickup Status"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {pickupStatus.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '250px' } }}>
                            <InputLabel id="return-status-label">Select Return Status</InputLabel>
                            <Select
                                labelId="return-status-label"
                                value={selectedReturnedStatus}
                                onChange={handleReturnedStatusChange}
                                label="Select Return Status"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {returnedStatus.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {isSmallScreen ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                            {currentProducts.map((item) => (
                                <IssueCards key={item.id} item={item} onDelete={handleDelete} />
                            ))}
                        </Box>
                    ) : (
                        <TableContainer
                            component={Paper}
                            elevation={0}
                            sx={{ overflowY: 'auto', mt: 2, border: '1px solid black', borderRadius: '20px' }}
                        >
                            <IssuableHeader />
                            <Box className="inventoryContainer" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                {currentProducts.map((item) => (
                                    <IssuableListItem key={item.id} product={item} onDelete={handleDelete} onDialogClose={triggerDataRefresh} />
                                ))}
                            </Box>
                            {totalPages > 1 && (
                                <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                            )}
                        </TableContainer>
                    )}
                </>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default IssuableTab;
