import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import BookWorkBench from "./workbench/BookWorkBench";
import { LocalizationProvider } from '@mui/x-date-pickers'; // Import LocalizationProvider
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Import AdapterDateFns for date handling

const CustomTab = ({ icon }) => (
  <Box sx={{ margin: '5px 0px' }}>
    {icon}
  </Box>
);

const TopBarComponent = ({ search, activeCategory, setActiveCategory, setSearch, activeView, setActiveView }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchActive, setSearchActive] = useState(false); // New state for toggling search field
  const [dialogOpen, setDialogOpen] = useState(false); // State to handle dialog open/close

  const handleChange = (event, newValue) => {
    setActiveCategory(newValue);
  };

  const handleViewChange = (event, newValue) => {
    setActiveView(newValue);
  };

  const toggleSearch = () => {
    setSearchActive(!searchActive); // Toggle between icon and search input
  };

  const handleDialogOpen = () => {
    setDialogOpen(true); // Open dialog
  };

  const handleDialogClose = () => {
    setDialogOpen(false); // Close dialog
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: isMobile ? 1 : 0,
          width: "100%",
          ...(activeCategory === 2 && { width: "97%" }), // Conditional for apparent right margin for Inventory tab
          padding: theme.spacing(1),
          marginBlock: "1rem",
          boxSizing: "border-box", // Ensure padding does not cause overflow
        }}
      >
        
        <Tabs
          value={activeCategory}
          onChange={handleChange}
          indicatorColor="none"
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons="auto"
          textColor="rgb(39,63,119)"
          sx={{
            flex: isMobile ? "1" : "0 1 auto",
            background: "rgb(242,243,252)",
            border: "2px solid rgba(39,63,119,0.6)",
            borderRadius: "12px",
            padding: isMobile ? "5px" : "8px",
            width: isMobile ? "100%" : "auto",
          }}
        >
          {["Machines", "Equipment", "Inventory"].map((label, index) => (
            <Tab
              key={index}
              label={label}
              sx={{
                marginInline:0.5,
                padding: "8px 16px",
                typography: "body1",
                fontWeight: "600",
                border: "2px solid rgba(39,63,119,0.6)",
                borderRadius: "8px",
                "&.Mui-selected": {
                  background: "rgb(39,63,119)",
                  color: "white",
                },
              }}
            />
          ))}
        </Tabs>

        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease-in-out",
              width: searchActive ? "auto" : "50px",
              borderRadius: "10px",
              overflow: "hidden",
              mr: -1.5,
            }}
          >
            {!searchActive && (
              <SearchIcon
                onClick={toggleSearch}
                sx={{
                  m: 1,
                  cursor: "pointer",
                  color: "rgb(39,63,119)",
                  background: "#F2F3FC",
                  border: "1px solid #273F77",
                  borderRadius: "12px",
                  fontSize: "2rem",
                  transition: "color 0.3s ease",
                }}
              />
            )}
            {searchActive && (
              <TextField
                fullWidth
                autoFocus
                value={search}
                className="searchBar"
                placeholder="Search Inventory.."
                id="outlined-start-adornment"
                sx={{
                  mr: 1.5,
                  width: "100%",
                  border: "1px solid rgb(39,63,119)",
                  color: "rgb(39,63,119)",
                  borderRadius: "10px",
                  opacity: searchActive ? 1 : 0,
                  transition: "opacity 0.3s ease-in-out",
                  "& .MuiFormControl": {
                    border: "none",
                  },
                }}
                onChange={(e) => setSearch(e.target.value)}
                onBlur={toggleSearch} // Close search field on blur
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "rgb(39,63,119)" }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>

          <Button
            variant="contained"
            sx={{
              mr: -3.5,
              backgroundColor: "#E79A2B",
              color: "white",
              borderRadius: "8px",
              padding: "4px 16px",
              textTransform: "none",
              border: "2px solid #212121",
            }}
            onClick={handleDialogOpen} // Open dialog when clicked
          >
            Book Work Bench
          </Button>

          {activeCategory !== 2 && (
            <Tabs
              indicatorColor="none"
              orientation="horizontal"
              value={activeView}
              onChange={handleViewChange}
              sx={{
                mt: 0.5,
                "& .MuiTab-root": {
                  padding: "0px",
                  marginInline: "2px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  minWidth: "0px",
                  fontSize: "2rem",
                },
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                },
              }}
            >
              <Tab
                label={
                  <CustomTab
                    icon={
                      <GridViewOutlinedIcon
                        sx={{
                          marginLeft: "20px",
                          color: activeView === 0 ? "white" : "rgb(39,63,119)",
                          background:
                            activeView === 0
                              ? "rgb(39,63,119)"
                              : "rgba(122,122,122,0.4)",
                          padding: "3px",
                          borderRadius: "8px",
                        }}
                      />
                    }
                  />
                }
                value={0}
              />
              <Tab
                label={
                  <CustomTab
                    icon={
                      <ViewListOutlinedIcon
                        sx={{
                          color: activeView === 0 ? "rgb(39,63,119)" : "white",
                          background:
                            activeView === 0
                              ? "rgba(122,122,122,0.4)"
                              : "rgb(39,63,119)",
                          padding: "3px",
                          borderRadius: "8px",
                        }}
                      />
                    }
                  />
                }
                value={1}
              />
            </Tabs>
          )}
        </Box>

        {/* Dialog for Book Work Bench */}
        {/* <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle>Book Work Bench</DialogTitle>
          <DialogContent>
            <BookWorkBench />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog> */}
        <BookWorkBench open={dialogOpen} onClose={handleDialogClose} />
      </Box>
    </LocalizationProvider>
  );
};

export default TopBarComponent;





// import React, { useState } from "react";
// import { Tabs, Tab, Box, TextField, Button, useMediaQuery, useTheme, InputAdornment } from "@mui/material";
// import SearchIcon from '@mui/icons-material/Search';
// import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
// import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

// const CustomTab = ({ icon }) => (
//   <Box sx={{ margin: '5px 0px' }}>
//     {icon}
//   </Box>
// );

// const TopBarComponent = ({ search, activeCategory, setActiveCategory, setSearch, activeView, setActiveView }) => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const [searchActive, setSearchActive] = useState(false); // New state for toggling search field

//   const handleChange = (event, newValue) => {
//     setActiveCategory(newValue);
//   };

//   const handleViewChange = (event, newValue) => {
//     setActiveView(newValue);
//   };

//   const toggleSearch = () => {
//     setSearchActive(!searchActive); // Toggle between icon and search input
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: isMobile ? "column" : "row",
//         justifyContent: "space-between",
//         alignItems: "center",
//         gap: isMobile ? 1 : 0,
//         width: "100%",
//         ...(activeCategory === 2 && { width: "97%" }), // Conditional for apparent right margin for Inventory tab
//         padding: theme.spacing(1),
//         marginBlock: "1rem",
//         boxSizing: "border-box", // Ensure padding does not cause overflow
//       }}
//     >
//       <Tabs
//         value={activeCategory}
//         onChange={handleChange}
//         indicatorColor="none"
//         textColor="rgb(39,63,119)"
//         sx={{
//           padding: "5px",
//           marginBottom: isMobile ? 1 : 0,
//           height: "auto",
//           background: "rgb(242,243,252)",
//           border: "2px solid rgba(39,63,119,0.6)",
//           borderRadius: "12px",
//         }}
//       >
//         <Tab
//           label="Machines"
//           sx={{
//             padding: "8px 32px",
//             height: "44px",
//             typography: "body1",
//             fontWeight: "600",
//             border: "2px solid rgba(39,63,119,0.6)",
//             borderRadius: "8px",
//             "&.Mui-selected": {
//               background: "rgb(39,63,119)",
//               color: "white",
//             },
//           }}
//         />
//         <Tab
//           label="Equipment"
//           sx={{
//             marginInline: "0.5rem",
//             padding: "8px 32px",
//             height: "44px",
//             typography: "body1",
//             fontWeight: "600",
//             border: "2px solid rgba(39,63,119,0.6)",
//             borderRadius: "8px",
//             "&.Mui-selected": {
//               background: "rgb(39,63,119)",
//               color: "white",
//             },
//           }}
//         />
//         <Tab
//           label="Inventory"
//           sx={{
//             padding: "8px 32px",
//             height: "44px",
//             typography: "body1",
//             fontWeight: "600",
//             border: "2px solid rgba(39,63,119,0.6)",
//             borderRadius: "8px",
//             "&.Mui-selected": {
//               background: "rgb(39,63,119)",
//               color: "white",
//             },
//           }}
//         />
//       </Tabs>

//       <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
//         <Box
//           sx={{
//             position: "relative",
//             display: "flex",
//             alignItems: "center",
//             transition: "all 0.3s ease-in-out",
//             width: searchActive ? "auto" : "50px",
//             // background: searchActive ? 'transparent' : 'rgba(39,63,119, 0.05)',
//             borderRadius: "10px",
//             overflow: "hidden",
//             // mr: searchActive ? 0 : -1.5,
//             mr: -1.5,
//           }}
//         >
//           {!searchActive && (
//             <SearchIcon
//               onClick={toggleSearch}
//               sx={{
//                 m: 1,
//                 cursor: "pointer",
//                 color: "rgb(39,63,119)",
//                 background: "#F2F3FC",
//                 border: "1px solid #273F77",
//                 borderRadius: "12px",
//                 fontSize: "2rem",
//                 transition: "color 0.3s ease",
//               }}
//             />
//           )}
//           {searchActive && (
//             <TextField
//               fullWidth
//               autoFocus
//               value={search}
//               className="searchBar"
//               placeholder="Search Inventory.."
//               id="outlined-start-adornment"
//               sx={{
//                 mr: 1.5,
//                 width: "100%",
//                 border: "1px solid rgb(39,63,119)",
//                 color: "rgb(39,63,119)",
//                 borderRadius: "10px",
//                 opacity: searchActive ? 1 : 0,
//                 transition: "opacity 0.3s ease-in-out",
//                 "& .MuiFormControl": {
//                   border: "none",
//                 },
//               }}
//               onChange={(e) => setSearch(e.target.value)}
//               onBlur={toggleSearch} // Close search field on blur
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <SearchIcon sx={{ color: "rgb(39,63,119)" }} />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           )}
//         </Box>

//         <Button
//           variant="contained"
//           sx={{
//             mr: -3.5,
//             backgroundColor: "#E79A2B",
//             color: "white",
//             borderRadius: "8px",
//             padding: "4px 16px",
//             textTransform: "none",
//             border: "2px solid #212121",
//           }}
//         >
//           Book Work Bench
//         </Button>

//         {activeCategory !== 2 && (
//           <Tabs
//             indicatorColor="none"
//             orientation="horizontal"
//             value={activeView}
//             onChange={handleViewChange}
//             sx={{
//               mt: 0.5,
//               "& .MuiTab-root": {
//                 padding: "0px",
//                 marginInline: "2px",
//                 alignItems: "flex-start",
//                 justifyContent: "flex-start",
//                 textTransform: "none",
//                 minWidth: "0px",
//                 fontSize: "2rem",
//               },
//               "& .MuiTabs-flexContainer": {
//                 flexDirection: "row",
//               },
//             }}
//           >
//             <Tab
//               label={
//                 <CustomTab
//                   icon={
//                     <GridViewOutlinedIcon
//                       sx={{
//                         marginLeft: "20px",
//                         color: activeView === 0 ? "white" : "rgb(39,63,119)",
//                         background:
//                           activeView === 0
//                             ? "rgb(39,63,119)"
//                             : "rgba(122,122,122,0.4)",
//                         padding: "3px",
//                         borderRadius: "8px",
//                       }}
//                     />
//                   }
//                 />
//               }
//               value={0}
//             />
//             <Tab
//               label={
//                 <CustomTab
//                   icon={
//                     <ViewListOutlinedIcon
//                       sx={{
//                         color: activeView === 0 ? "rgb(39,63,119)" : "white",
//                         background:
//                           activeView === 0
//                             ? "rgba(122,122,122,0.4)"
//                             : "rgb(39,63,119)",
//                         padding: "3px",
//                         borderRadius: "8px",
//                       }}
//                     />
//                   }
//                 />
//               }
//               value={1}
//             />
//           </Tabs>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default TopBarComponent;
