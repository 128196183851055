import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button, Grid } from '@mui/material';
import StudentWorkshopList from 'utilities/api/workshop/StudentWorkshopList';
import ReportIssueAPI from 'utilities/api/ReportIssueApi';
import { useNavigate } from 'react-router-dom';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';

const CardItem = ({ number, title, description, buttonColor, buttonText, backgroundColor, borderColor, onClickEvent, showNoData }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                padding: '1rem 0rem',
                borderRadius: '12px',
                position: 'relative',
                border: `1px solid ${borderColor}`,
                textAlign: 'center',
                backgroundColor: backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '104px',
                width: '100%',
            }}
        >
            <Button
                onClick={onClickEvent}
                variant="contained"
                sx={{
                    position: 'absolute',
                    top: '-14px',
                    right: '16px',
                    backgroundColor: buttonColor,
                    borderRadius: '50px',
                    padding: '4px 16px',
                    zIndex: 3,
                }}
            >
                <Typography sx={{ fontSize: '16px', color: '#fff' }}>
                    {buttonText}
                </Typography>
            </Button>
            {showNoData ? (
                <Box display="flex" alignItems="center">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '100px', marginRight: '16px' }} />
                    <Typography variant="body1" color="textSecondary">
                        No {title.toLowerCase()} found
                    </Typography>
                </Box>
            ) : (
                <>
                    <Typography variant="h4" sx={{ fontSize: '22px', fontWeight: '700', color: '#212121', textAlign: 'left' }}>
                        {number}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '14px', textTransform: 'capitalize', fontWeight: '500', color: '#212121', mt: 1 }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px', textTransform: 'lowercase', fontWeight: '500', color: '#7a7a7a', mt: 1 }}>
                        {description}
                    </Typography>
                </>
            )}
        </Paper>
    );
};

const StatisticsCards = ({ user }) => {
    const [workshopCount, setWorkshopCount] = useState(0);
    const [reportCount, setReportCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const workshops = await StudentWorkshopList.WorkshopListGet();
                const today = new Date();
                const completedWorkshops = workshops.filter(workshop => {
                    const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
                    if (workshopEndDate < today) {
                        const attendee = workshop.attendees.find(att => att.user === user.id);
                        return attendee && attendee.is_present;
                    }
                    return false;
                });
                setWorkshopCount(completedWorkshops.length);
            } catch (error) {
                console.error("Failed to fetch workshops", error);
            }

            try {
                const reports = await ReportIssueAPI.ReportListGet();
                const userReports = reports.filter(report => report.user === user.id);
                setReportCount(userReports.length);
            } catch (error) {
                console.error("Failed to fetch reports", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const handleWorkshopClick = () => {
        navigate("/training", { state: { tab: 1 } });
    };

    const handleTutorialClick = () => {
        navigate("/training", { state: { tab: 0 } });
    };

    const handleReportClick = () => {
        navigate("/settings");
    };

    if (loading) {
        return <Typography align="center">Loading data...</Typography>;
    }

    return (
        <Box sx={{ flexGrow: 1, width: '100%' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <CardItem
                        number={workshopCount}
                        title="Workshops Completed"
                        description={`${workshopCount > 0 ? '15% increase from last week' : 'No increase from last week'}`}
                        buttonColor="#978fed"
                        buttonText="Explore Now"
                        backgroundColor="#e7e5f9"
                        borderColor="#978fed"
                        onClickEvent={handleWorkshopClick}
                        showNoData={workshopCount === 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <CardItem
                        number={"?"}
                        title="Tutorials Watched"
                        description="20% increase from last week"
                        buttonColor="#ca628d"
                        buttonText="Explore Now"
                        backgroundColor="#f6e7ed"
                        borderColor="#ca628d"
                        onClickEvent={handleTutorialClick}
                        showNoData={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <CardItem
                        number={reportCount}
                        title="Reports Issued"
                        description={`${reportCount > 0 ? '10% increase from last week' : 'No increase from last week'}`}
                        buttonColor="#6eba65"
                        buttonText="Explore Now"
                        backgroundColor="#dfeddd"
                        borderColor="#6eba65"
                        onClickEvent={handleReportClick}
                        showNoData={reportCount === 0}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default StatisticsCards;
