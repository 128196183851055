import React from "react";
import { Card, CardContent, Typography, Box, Link, useMediaQuery, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import IndividualWorkshopCard from "./IndividualWorkshopCard";
import WorkshopContent from "./WorkshopContent";
import { toggleDetails, setWorkshopData, setWorkshopCategory } from "../../../redux/slices/workshopSlice";

const cardContainerStyles = {
  borderRadius: 8,
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  padding: "16px",
  marginBottom: "16px",
  overflowY: "auto",
  maxHeight: "50vh", // Adjust the height as needed
};

const contentStyles = {
  flex: "1 0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
};

const WorkshopCards = ({ workshopData }) => {
  const dispatch = useDispatch();
  const { access, user } = useSelector((state) => state.user);

  const isUserEligible = (workshop) => {
    return (
      user.current_year &&
      workshop.eligible_years.includes(user.current_year) &&
      user.department &&
      workshop.eligible_branches.includes(user.department)
    );
  };

  const today = new Date();

  const upcomingWorkshops = workshopData.filter((workshop) => {
    const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
    return workshopStartDate > today && isUserEligible(workshop);
  });

  const ongoingWorkshops = workshopData.filter((workshop) => {
    const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
    const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
    return workshopStartDate <= today && workshopEndDate >= today && isUserEligible(workshop);
  });

  const completedWorkshops = workshopData.filter((workshop) => {
    const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
    if (workshopEndDate < today) {
      const attendee = workshop.attendees.find((att) => att.user === user.id);
      return attendee && attendee.is_present;
    }
    return false;
  });

  const handleViewAll = (workshops, workshopCategory) => {
    dispatch(toggleDetails());
    dispatch(setWorkshopData(workshops));
    dispatch(setWorkshopCategory({ payload: workshopCategory }));
  };

  // Use MediaQuery to detect screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (sm or smaller)

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/* Upcoming and Ongoing Workshops - Responsive Layout */}
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} width="100%" mb={2}>
        {/* Upcoming Workshops */}
        <Box flex="1" mb={isSmallScreen ? 2 : 0} mr={isSmallScreen ? 0 : 2}>
          <Card style={{ ...cardContainerStyles, maxHeight: "50vh" }}>
            <CardContent style={contentStyles}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography gutterBottom variant="h5" component="div">
                  Upcoming Workshops
                </Typography>
                <Link href="#" variant="body2" onClick={() => handleViewAll(upcomingWorkshops, "Upcoming")}>
                  View All
                </Link>
              </Box>
              {upcomingWorkshops.slice(0, 2).map((workshop) => (
                <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
              ))}
              {upcomingWorkshops.length > 2 && (
                <Box mt={2}>
                  {upcomingWorkshops.slice(2).map((workshop) => (
                    <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>

        {/* Ongoing Workshops */}
        <Box flex="1">
          <Card style={{ ...cardContainerStyles, maxHeight: "50vh" }}>
            <CardContent style={contentStyles}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography gutterBottom variant="h5" component="div">
                  Ongoing Workshops
                </Typography>
                <Link href="#" variant="body2" onClick={() => handleViewAll(ongoingWorkshops, "Ongoing")}>
                  View All
                </Link>
              </Box>
              {ongoingWorkshops.slice(0, 2).map((workshop) => (
                <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
              ))}
              {ongoingWorkshops.length > 2 && (
                <Box mt={2}>
                  {ongoingWorkshops.slice(2).map((workshop) => (
                    <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>

      {/* Completed Workshops - Responsive Layout */}
      <Box width="100%">
        <Card style={{ ...cardContainerStyles, maxHeight: "50vh" }}>
          <CardContent style={contentStyles}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="h5" component="div">
                Completed Workshops
              </Typography>
            </Box>
            <WorkshopContent workshopData={completedWorkshops} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default WorkshopCards;
