import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Grid,
} from '@mui/material';
import BookMachineAPIs from 'utilities/api/StudentBookMachine';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import { useNavigate } from 'react-router-dom';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    const formattedTime = date.toTimeString().split(' ')[0];
    return `${formattedDate}, ${formattedTime}`;
};

const HistoryTables = ({ user }) => {
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const machinesData = await BookMachineAPIs.ReservedMachineGet();
                const filteredMachines = machinesData.filter(machine => machine.reserved_by === user.id);

                const issuesData = await IssueInventoryAPIs.IssuedInventoryGet();
                const filteredIssues = issuesData.filter(issue => issue.issued_by === user.id);

                const purchasesData = await PurchaseInventoryAPIs.PurchasedInventoryGet();
                const filteredPurchases = purchasesData.filter(purchase => purchase.purchased_by === user.id);

                setTableData1(filteredMachines);
                setTableData2(filteredIssues);
                setTableData3(filteredPurchases);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user]);

    const renderNoData = (message) => (
        <TableRow>
            <TableCell colSpan={4} style={{ textAlign: 'center', padding: '30px', border: '0px' }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '160px', marginRight: '16px' }} />
                    <Typography variant="body1" color="textSecondary">
                        {message}
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );

    return (
        <Box
            sx={{
                marginTop: 3,
                backgroundColor: '#F9FAFE',
                border: '1px solid #d3d3d3',
                borderRadius: '24px',
                padding: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 2,
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    onClick={() => navigate('/records')}
                    variant="contained"
                    sx={{
                        backgroundColor: '#FFA726',
                        color: 'white',
                        maxWidth: 200,
                        flexGrow: { xs: 1, sm: 0 },
                        mb: { xs: 2, sm: 0 },
                    }}
                >
                    Explore Now
                </Button>
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: 24,
                        fontWeight: '700',
                        color: '#000',
                        textAlign: 'center',
                        flexGrow: 1,
                    }}
                >
                    Your History
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexGrow: { xs: 1, sm: 0 },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                        <Box sx={{ width: 20, height: 20, backgroundColor: '#FFECB3', marginRight: 1 }}></Box>
                        <Typography variant="subtitle2" sx={{ color: '#000' }}>
                            Bookings
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                        <Box sx={{ width: 20, height: 20, backgroundColor: '#E3F2FD', marginRight: 1 }}></Box>
                        <Typography variant="subtitle2" sx={{ color: '#000' }}>
                            Issuables
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 20, height: 20, backgroundColor: '#FCE4EC', marginRight: 1 }}></Box>
                        <Typography variant="subtitle2" sx={{ color: '#000' }}>
                            Purchases
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TableContainer
                        component={Paper}
                        sx={{
                            border: '1px solid #FFE082',
                            borderRadius: 2,
                            minHeight: 300,
                            maxHeight: 350,
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead sx={{ backgroundColor: '#FFECB3' }}>
                                <TableRow>
                                    <TableCell align="center">Item Name</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Due Date</TableCell>
                                    <TableCell align="center">Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData1.length > 0 ? (
                                    tableData1.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.approved}</TableCell>
                                            <TableCell align="center">{row.reserved_date}</TableCell>
                                            <TableCell align="center">
                                                {new Date(row.start_time).toLocaleTimeString()}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    renderNoData('No bookings found')
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TableContainer
                        component={Paper}
                        sx={{
                            border: '1px solid #BBDEFB',
                            borderRadius: 2,
                            minHeight: 300,
                            maxHeight: 350,
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead sx={{ backgroundColor: '#E3F2FD' }}>
                                <TableRow>
                                    <TableCell align="center">Item Name</TableCell>
                                    <TableCell align="center">Picked</TableCell>
                                    <TableCell align="center">Returned</TableCell>
                                    <TableCell align="center">Due Date</TableCell>
                                    <TableCell align="center">Category</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData2.length > 0 ? (
                                    tableData2.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.pickup_}</TableCell>
                                            <TableCell align="center">{row.returned_}</TableCell>
                                            <TableCell align="center">{row.issued_from}</TableCell>
                                            <TableCell align="center">{row.project_name}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    renderNoData('No issues found')
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TableContainer
                        component={Paper}
                        sx={{
                            border: '1px solid #F8BBD0',
                            borderRadius: 2,
                            minHeight: 300,
                            maxHeight: 350,
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead sx={{ backgroundColor: '#FCE4EC' }}>
                                <TableRow>
                                    <TableCell align="center">Item Name</TableCell>
                                    <TableCell align="center">Picked</TableCell>
                                    <TableCell align="center">Date of Purchase</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData3.length > 0 ? (
                                    tableData3.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.pickup_}</TableCell>
                                            <TableCell align="center">{formatDate(row.purchase_datetime)}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    renderNoData('No purchases found')
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HistoryTables;
