import React, { useState } from 'react';
import { Card, CardContent, Typography, CardMedia, Box, Button } from '@mui/material';
import InventoryDialog from 'components/popups/InventoryDialog';
import ImageBaseURL from 'utilities/api/axios';

const InventoryResponsiveCard = ({ product, searchQuery }) => {
  const [openDialog, setDialogOpen] = useState(false);

  const tagStyles = {
    Exhaustible: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#FF6347',
      color: '#FFFFFF',
      display: 'inline-block',
      fontSize: '12px',
    },
    NonExhaustible: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#32CD32',
      color: '#FFFFFF',
      display: 'inline-block',
      fontSize: '12px',
    },
    Purchase: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#D8DBF5',
      color: '#273F77',
      display: 'inline-block',
      fontSize: '12px',
    },
    InLabOnly: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#FFF1E4',
      color: '#E79A2B',
      display: 'inline-block',
      fontSize: '12px',
    },
    Issue: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#CCE5FF',
      color: '#007BFF',
      display: 'inline-block',
      fontSize: '12px',
    },
    Free: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#DCFDDB',
      color: '#107C10',
      display: 'inline-block',
      fontSize: '12px',
    },
    HighValue: {
      borderRadius: '50px',
      padding: '4px 8px',
      background: '#FEFFD2',
      color: '#D0A300',
      display: 'inline-block',
      fontSize: '12px',
    },
  };

  const buttonStyles = {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #273F77',
    fontWeight: '600',
    color: '#273F77',
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: '#273F77', color: '#FFFFFF' },
  };

  // Extract tags from product data dynamically
  const allowedTabs = [product.tag_1, product.tag_2, product.tag_3, product.tag_4].filter(Boolean);

  const filterData = (product, allowedTabs) => {
    const query = searchQuery.toLowerCase();
    const { name, category, stock_available } = product;
    const hasMatchingTag = allowedTabs.some((tag) => tag.toLowerCase().includes(query));
    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      hasMatchingTag ||
      (stock_available && stock_available.toString().toLowerCase().includes(query))
    );
  };

  const filtered = filterData(product, allowedTabs);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {filtered && (
        <Card
          sx={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            margin: '16px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            boxShadow: 'none',
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: '200px',
              objectFit: 'cover',
            }}
            image={
              product.image
                ? `${ImageBaseURL.defaults.baseURL}${product.image}`
                : './machine.png'
            }
            alt={product.name}
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              Category: {product.category}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Available Stock: {product.stock_available}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '4px',
                marginTop: '8px',
              }}
            >
              {allowedTabs.map((tagValue, index) => (
                <div key={index} style={tagStyles[tagValue]}>
                  {tagValue}
                </div>
              ))}
            </Box>
          </CardContent>
          <Box
            sx={{
              padding: '16px',
            }}
          >
            {allowedTabs.includes('Free') ? (
              <Typography sx={{ fontWeight: '520', color: '#273F77', textAlign: 'center' }}>
                Free to use
              </Typography>
            ) : (
              <Button onClick={handleOpenDialog} sx={buttonStyles}>
                Get
              </Button>
            )}
          </Box>
        </Card>
      )}
      <InventoryDialog
        open={openDialog}
        onClose={handleCloseDialog}
        machineId={product.id}
        allowedTabs={allowedTabs}
        tagStyles={tagStyles}
      />
    </>
  );
};

export default InventoryResponsiveCard;
